import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as dataSets from "./sensorData";
import "./playAndLearn.scss";
import { BsCheck2Square } from "react-icons/bs";
import { AiOutlineCloseSquare } from "react-icons/ai";
import {
  getLogsBySessionId,
  getSessionsByManikinId,
  userSessionAll,
} from "../../api/mqtt.rest";
import { socket } from "../../config/socket";
import gsap from "gsap";
import { useRef } from "react";
import SOCKET_DATA_TABLE from "../../components/table-component/SocketDataTable";
import { LuPlayCircle } from "react-icons/lu";
import { FaRegCircleStop } from "react-icons/fa6";
import { MdOutlineReplay } from "react-icons/md";
import Delete from "../../components/icons/Delete";
import PlayButton from "../../components/icons/PlayButton";
import Event from "../../components/icons/Event";
import Sensor from "../../components/icons/Sensor";
import Close from "../../components/icons/Close";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClinicalNotes from "../../components/icons/ClinicalNotes";
import { index } from "d3";
import DebriefingIcon from "../../components/icons/DebriefingIcon";
import TestKnowledge from "../../components/icons/TestKnowledge";
import AssignTestModal from "../../components/common/AssignTestModal";
import CustomModal from "../../components/common/CustomModal";
import TooltipUi from "../../components/common/TooltipUi";
import More from "../../components/icons/More";
import SendIcon from "../../components/icons/SendIcon";
import TickIcon from "../../components/icons/TickIcon";
import { GrClose } from "react-icons/gr";
import { GrCheckmark } from "react-icons/gr";
import { useSearchParams } from "react-router-dom";
import Speech from "react-speech";
import EasySpeech from "easy-speech";
import { CSVLink, CSVDownload } from "react-csv";
import { MdDownload } from "react-icons/md";
import { UpdateSessionName } from "../../api/mqtt.rest";
import { toast } from "react-toastify";
import { SOCKET_DATA_HEADERS_CSV } from "../../constant";
import SessionsContainer from "../../components/Student-sec/SessionsContainer";
import { Add, Expand, MoreHoriz, Search } from "@mui/icons-material";
import ExpandScreen from "../../components/icons/ExpandScreen";
import ResizeScreen from "../../components/icons/ResizeScreen";
import { createPortal } from "react-dom";
import LogTable from "../../components/Student-sec/LogTable";
import SensorData from "../../components/Student-sec/SensorData";
import { UpdateSessionFn } from "../../utils/student-screen";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import Stop from "../../components/icons/Stop";
import ListenIcon from "../../components/icons/ListenIcon";
import PauseDebriefIcon from "../../components/icons/PauseDebriefIcon";
import Debriefing from "../../components/icons/Debriefing";

import { HiMiniSpeakerWave } from "react-icons/hi2";
import { MdPauseCircle } from "react-icons/md";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import Button from "@mui/material/Button";
import { MdListAlt } from "react-icons/md";
import { FaExpand } from "react-icons/fa";
import { MdSensors } from "react-icons/md";
import { LuShrink } from "react-icons/lu";
import { MdAssignment } from "react-icons/md";
const screenHeight = window.screen.height / 4;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="pnl-tab-panel"
      {...other}
    >
      {value === index && <div className="tab-panel-div py-2">{children}</div>}
    </div>
  );
}

const PlayLearnTabsComponent = forwardRef((props, ref) => {
  const [value, setValue] = useState(0);
  const [sessionDataList, setSessionDataList] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [selectedSensorData, setSelectedSensorData] = useState([]);
  const [isSessionDataLoading, setIsSessionDataLoading] = useState(false);
  // const [isRunning, setIsRunning] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [selectOption, setSelectOption] = useState("Option 1");
  const [sessionId, setSessionId] = useState("");
  const [datafetched, setDatafetched] = useState(false);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  // const [animate,setAnimate]=useState()
  // identifies the selected sessions in search modal - SessionsContainer
  const [alreadySelectedSessionInd, setAlreadySelectedSessionInd] = useState(
    []
  );
  const {
    selectedManikin,
    simulateSession,
    macId,
    init,
    timer,
    HideUpperSecsAction,
    HideUpperSecs,
    stopAnimation,
    isRunning,
    activeTabIndex,
    activeTabIndexSelection,
    onTabClick,
    sessionIdData,
    getSelectedSessionId,
    SwapBtn,
    time,
    listingMessage,
    uSessionfilter,
    seekerListMsg,
    isOnline,
    firstThreeSessionArray,
    setFirstThreeSessionArray,
    liveSessionState,
    setLiveSessionState,
    hideSec,
    setHideSec,
    switch1,
    setSwitch1,
    selectedSessionName,
    setSelectedSessionName,
    replayRunning,
    setReplayRunning,
  } = props;
  const initialTiltPos = 0;
  const sensorDataRef = useRef(null);
  // const [elapsedTime, setElapsedTime] = useState(0);
  // const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [modalHide, setModalHide] = useState(false);
  const [replaySessionId, setReplaySessionId] = useState("");
  const [modalHide1, setModalHide1] = useState(false);

  const [params, setParams] = useSearchParams();

  const qSessionId = params.get("sId");
  const qMId = params.get("mId");
  const uId = params.get("uId");
  const orgId = localStorage.getItem("orgId");
  const [sensorData, setSensorData] = useState([]);
  const [sensorMessages, setSensorMessages] = useState([]);
  const [bottomSecHide, setBottomSecHide] = useState(true);
  const sessionArray = [];
  const [scrollLeftVisible, setScrollLeftVisible] = useState(false);
  const [scrollRightVisible, setScrollRightVisible] = useState(true);
  const tabsContainerRef = useRef(null);
  const [sessionNumber, setSessionNumber] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [results, setResults] = useState({});
  const [btnHide, setBtnHide] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [sessionName, setSessioName] = useState("");
  const userType = localStorage.getItem("userType");
  const [manikinData, setManikinData] = useState([]);
  const [manikinId, setManikinId] = useState("");
  const messages = [];
  const debriefingCount = useRef(0);
  const debriefingTimer = useRef(null);
  const [allSessionsContainer, setAllSessionContainer] = useState([]);
  const [logListTest, setLogListTest] = useState([]);
  const [openSeachModal, setOpenSearchModal] = useState(false);
  const [briefingData, setBriefingData] = useState("");
  const handleExpandClick = () => {
    setModalHide1(true);
  };

  useEffect(() => {
    setSensorMessages(...listingMessage);
  }, []);

  useEffect(() => {
    setReplayRunning(SwapBtn);
  }, [SwapBtn]);

  useEffect(() => {
    init(initialTiltPos);
    // getAllManikins();
  }, []);

  useEffect(() => {
    const sensorDataContainer = sensorDataRef?.current;
    const lastItem = sensorDataContainer?.lastElementChild;
    lastItem?.scrollIntoView({ behavior: "smooth" });
  }, [selectedSensorData, value]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useImperativeHandle(ref, () => ({
    async refreshPage() {
      await userSessionAllDetails();
    },
    onStopReplay() {
      btnReplay();
    },
  }));
  const userSessionAllDetails = async () => {
    try {
      const pageNumber = 1;
      const pageSize = 100;
      const data = {
        userId: uId,
        filter: uSessionfilter,
      };

      const res = await userSessionAll(pageNumber, pageSize, data);
      // setUSessionDetails(res?.data?.data?.data)
      loadAllSessions(res?.data?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadAllSessions = (resData) => {
    setSessionDataList(resData);
    resData?.forEach((d) => {
      if (d?.status === "running") {
        sessionIdData(d);
      }
    });

    if (
      resData.length > 0 &&
      (qSessionId == null || qMId !== selectedManikin) &&
      (qMId == null || qMId !== selectedManikin)
    ) {
      handleSessionSelection(resData[0], 0, resData.length);
    } else {
      resData.forEach((s, index) => {
        if (s?.session_id === qSessionId) {
          handleSessionSelection(resData[index], index, resData.length);
        }
      });
      setTimeout(() => {
        document.getElementById(`#tab_${qSessionId}`)?.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }

    // setSessionNumber(resData?.length);
  };

  useEffect(() => {
    if (!isRunning) {
      if (uId && userType === "instructor" && uSessionfilter !== "") {
        userSessionAllDetails();
      }
    }
  }, [uSessionfilter]);

  useEffect(() => {
    if (!isRunning && userType === "user") {
      setIsSessionDataLoading(true);
      getSessionsByManikinId(selectedManikin)
        .then((resp) => {
          const resData = resp?.data?.data?.data;
          loadAllSessions(resData);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSessionDataLoading(false);
        });
    }
  }, [selectedManikin, isRunning]);

  const handleSessionSelection = (sessionData, index, numberOfSessions) => {
    debriefingCount.current = 0;
    debriefingTimer.current = null;
    activeTabIndexSelection(index);
    setSessionNumber(numberOfSessions);
    setReplaySessionId(sessionData?.session_id);
    getSelectedSessionId(sessionData?.session_id);
    getDataBySessionId(sessionData?.session_id, sessionData, index);
  };

  const getDataBySessionId = async (sessionId, sessionData, index) => {
    try {
      const resp = await getLogsBySessionId(sessionId);
      const sensorDataList = resp?.data?.data?.data?.map(
        (e) => e?.sensor_value
      );
      console.log(sensorDataList);
      // setSensorData(sensorDataList);
      const manId = resp?.data?.data?.data[0]?.mnk_id;
      if (sessionId) {
        // onTabClick(sensorDataList, manId, sessionDataList[index]);
        onTabClick(sensorDataList, manId, sessionData);
      }
      setSelectedSessionName(sessionData.session_name);
      setCurrentSessionId(sessionData.session_id);
      setSessionData(resp?.data?.data);
      setSelectedSensorData(sensorDataList);
      setLogListTest(sensorDataList);

      if (sessionId && liveSessionState) {
        // console.log("called brfng data");
        // let fnCount = 0;
        breifingData(resp?.data?.data, sessionData, sessionData, index);
        setDatafetched(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   let thisIsForBrfData = async () => {
  //     await getDataBySessionId(currentSessionId, sessionData, 0);
  //     setLiveSessionState(false);
  //   };
  //   let timeIntID = setTimeout(() => thisIsForBrfData(), 20000)
  //   return () => clearTimeout(timeIntID);
  // }, [datafetched]);

  const breifingData = (sDataFromLog, sData, index) => {
    if (debriefingCount.current < 3 && briefingData.length < 100) {
      debriefingTimer.current = setTimeout(() => {
        getDataBySessionId(sData?.session_id, sData, index);
      }, 2000);

      debriefingCount.current = debriefingCount.current + 1;
    } else {
      console.log("came to the else part!!!", debriefingCount.current);
      debriefingCount.current = 0;
      setLiveSessionState(false);
      clearTimeout(debriefingTimer.current);
      // debriefingTimer.current = null;
    }
  };
  useEffect(() => {
    timer(isRunning);
    if (isRunning && replayRunning) {
      setReplayRunning(false);
    }
    if (isRunning) {
      setSessionData({});
    }
  }, [isRunning]);

  const btnReplay = () => {
    simulateSession(selectedSensorData);
    setReplayRunning((prev) => !prev);
    if (time === false) {
      setSwitch1((prev) => !prev);
    }
  };

  const StopReplay = () => {
    stopAnimation();
  };

  const handleDeleteClick = () => {
    HideUpperSecsAction();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  const [sessionHide, setSessionHide] = useState(true);
  const [sensorHide, setSensorHide] = useState(false);

  const handleSessionClick = () => {
    setSessionHide(true);
    setSensorHide(false);
    setValue(0);
  };

  const handleSensorClick = () => {
    setSensorHide(true);
    setSessionHide(false);
    setValue(1);
  };

  const handleScroll = () => {
    const container = tabsContainerRef.current;

    if (container) {
      setScrollLeftVisible(container.scrollLeft > 0);
      setScrollRightVisible(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  const handleScrollLeft = () => {
    const container = tabsContainerRef.current;
    if (container) {
      container.scrollBy({
        left: -container.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    const container = tabsContainerRef.current;
    if (container) {
      container.scrollBy({
        left: container.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  const handleSessionDeleteClick = (indexes) => {
    // console.log(firstThreeSessionArray)
    // return;
    let sessionId = firstThreeSessionArray[indexes].session_id;
    const newArray = firstThreeSessionArray.filter((item) => {
      return item?.session_id !== sessionId;
    });
    handleSessionSelection(
      firstThreeSessionArray[indexes],
      newArray.length - 1
    );
    let updatedAlreadySelectedSessIndArr = alreadySelectedSessionInd.filter(
      (item) => item !== sessionId
    );
    console.log(updatedAlreadySelectedSessIndArr, sessionId);
    setAlreadySelectedSessionInd(updatedAlreadySelectedSessIndArr);
    setFirstThreeSessionArray(newArray);
  };

  const handleTestKnowledgeClick = () => {
    setModalHide(true);
  };
  const onClose = () => {
    setModalHide(false);
  };

  const onClose1 = () => {
    setModalHide1(false);
  };

  const handleOptionClick = (questionId, optionId) => {
    const isCorrect = checkAnswer(questionId, optionId);
    setSelectedOptions((prevState) => ({
      ...prevState,
      [questionId]: { optionId, isCorrect },
    }));
  };

  const isOptionSelected = (questionId, optionId) => {
    return selectedOptions[questionId]?.optionId === optionId;
  };
  const checkAnswer = (questionId, optionId) => {
    const question = sessionData?.question.find(
      (q) => q.question_id === questionId
    );
    const correctOption = question?.options?.find(
      (option) => option.answer === true
    );
    return correctOption?.option_id === optionId;
  };

  const handleSubmit = () => {
    const newResults = {};
    for (const questionId in selectedOptions) {
      const { optionId, isCorrect } = selectedOptions[questionId];
      newResults[questionId] = { optionId, isCorrect };
    }
    setResults(newResults);
  };

  const getOptionName = (questionId) => {
    const optionId = selectedOptions[questionId]?.optionId;
    if (!optionId) return "";
    const question = sessionData?.question.find(
      (q) => q.question_id === questionId
    );
    const option = question.options.find((o) => o.option_id === optionId);
    return option.choices;
  };

  const textToSpeech = async (action) => {
    try {
      const textWithoutHtml =
        sessionData?.sessionDebriefing?.debriefing?.replace(/<[^>]+>/g, "");
      setBtnHide(!btnHide);

      await EasySpeech.init();

      const voices = EasySpeech.voices();
      const voice = voices[2];

      const utterance = new SpeechSynthesisUtterance(textWithoutHtml);
      utterance.voice = voice;
      utterance.onerror = function (event) {
        console.error("Speech synthesis error:", event);
      };

      utterance.onend = function () {
        console.log("Speech synthesis complete.");
        setBtnHide(false);
      };

      if (action === "play") {
        await EasySpeech.speak(utterance);
      } else {
        EasySpeech.cancel();
      }
    } catch (error) {
      console.error("An error occurred during speech synthesis:", error);
    }
  };

  const handleDoubleClick = (ind) => {
    setEditIndex(ind);
    setSessioName(
      sessionDataList[ind]?.session_name || sessionDataList[ind]?.session_id
    );
  };

  const handleEditChange = (value) => {
    setSessioName(value);
  };

  const handleEditSubmit = (ind, id, e) => {
    e.preventDefault();
    UpdateSession(id, ind);
    setEditIndex(null);
  };

  const UpdateSession = async (id, ind) => {
    await UpdateSessionFn(
      id,
      ind,
      sessionName,
      sessionDataList,
      setSessionDataList,
      setSelectedSessionName
    );
  };

  useEffect(() => {
    let newArray1 = [];
    for (let i = 0; i < 3; i++) {
      newArray1.push(sessionDataList[i]);
    }
    setFirstThreeSessionArray(newArray1);
    let lenOfSessions = sessionDataList.length;
    let newArray2 = [];
    for (let j = 3; j < lenOfSessions; j++) {
      newArray2.push(sessionDataList[j]);
    }
    setAllSessionContainer(newArray2);
  }, [sessionDataList]);

  // jsx starts

  return (
    <>
      <div className="">
        {/* first row starts */}
        <Box
          className="flex justify-between items-center w-[100%] overflow-x-scroll pb-2"
          sx={{ paddingRight: 1, paddingLeft: 1 }}
        >
          {/* left section of the upper tab where we see the session names -- starts */}
          <div className="min-w-[0px] max-w-[1100px] overflow-x-scroll">
            <div className="tab-slider">
              {firstThreeSessionArray?.length > 6 && (
                <div
                  className={`tab-slider-arrow visible text-textColor`}
                  onClick={handleScrollLeft}
                >
                  <BsArrowLeftSquareFill />
                </div>
              )}

              <div
                className="tab-slider-tabs-container"
                ref={tabsContainerRef}
                onScroll={handleScroll}
              >
                <div className="tab-slider-tabs flex justify-start gap-2 items-center max-w-full">
                  {/* if the session is running */}
                  {isRunning && (
                    <div
                      className={`tab-slider-tab last-tab border-blue-500 border-b-2`}
                    >
                      <span>
                        SESSION
                        <span className="relative text-xs bottom-[2.3px] ">
                          LIVE{" "}
                          <div className="absolute  w-[7px] h-[7px] rounded-full bottom-1 right-[-0.5rem]"></div>
                        </span>
                      </span>
                    </div>
                  )}

                  {isSessionDataLoading ? (
                    <div className="flex flex-row w-100">
                      <CircularProgress />
                    </div>
                  ) : sessionDataList?.length > 0 ? (
                    <>
                      {firstThreeSessionArray?.map((session, ind) => {
                        const sessionNumber = sessionDataList?.length - ind;
                        //only show those tab which has status "stop"
                        if (session?.status == "stop") {
                          return (
                            <div
                              id={`#tab_${session?.session_id}`}
                              onClick={() =>
                                handleSessionSelection(
                                  session,
                                  ind,
                                  sessionNumber
                                )
                              }
                              style={{ width: "max-content" }}
                              className={`tab-slider-tab flex justify-between items-center ${
                                ind === activeTabIndex && !isRunning
                                  ? " border-blue-500 border-b-2 text-black"
                                  : "text-slate-400"
                              } ${
                                isRunning || replayRunning
                                  ? "cursor-not-allowed pointer-events-none"
                                  : "cursor-pointer pointer-events-auto"
                              }`}
                              key={ind}
                            >
                              {editIndex === ind ? (
                                <form
                                  onSubmit={(e) =>
                                    handleEditSubmit(
                                      ind,
                                      session?.session_id,
                                      e
                                    )
                                  }
                                >
                                  <input
                                    type="text"
                                    value={sessionName}
                                    className="w-[100%] focus:border-none focus:outline-none"
                                    autoFocus
                                    onChange={(e) =>
                                      handleEditChange(e.target.value)
                                    }
                                  />
                                </form>
                              ) : (
                                <div
                                  className={`${
                                    session?.is_assessment
                                      ? "text-[var(--primary-color)]"
                                      : "text-textColor"
                                  }`}
                                  onDoubleClick={() => handleDoubleClick(ind)}
                                >
                                  {session?.session_name
                                    ? session?.session_name
                                    : "" + session?.session_id}
                                  {/* { isRunning && <div className="relative text-xs text-[var(--text-primary-color)] bottom-[2.3px] ">
                            LIVE{" "}
                            <div className="absolute bg-[var(--text-primary-color)] w-[7px] h-[7px] rounded-full bottom-1 right-[-0.5rem]"></div>
                          </div>} */}
                                </div>
                              )}
                              {/* } */}
                              <div
                                className="flex justify-center items-center p-2"
                                onClick={() => handleSessionDeleteClick(ind)}
                              >
                                <Close color="var(--text-primary-color)" />
                              </div>
                            </div>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="h-max">
                      {/* Session data not available for this manikin */}
                    </div>
                  )}
                </div>
              </div>
              {firstThreeSessionArray?.length > 5 && (
                <div
                  className={`tab-slider-arrow visible text-textColor`}
                  onClick={handleScrollRight}
                >
                  <BsArrowRightSquareFill />
                </div>
              )}
              {macId && (
                <span
                  onClick={() => setOpenSearchModal(true)}
                  className="cursor-pointer  "
                >
                  <Tooltip title="Search and add session">
                    <Add className="text-textColor" />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
          {/* left section of the upper tab where we see the session names -- ends */}
          {/* // )}  */}
          {/* right section of the upper tab where we see the session names -- starts */}
          {macId && (
            <div
              aria-label="play-and-learn-tabs"
              className="play-learn-tabs  flex "
            >
              <div className="flex gap-[1px] ">
                <span
                  className={`${
                    sessionHide
                      ? "border-b-[0.1px] border-[#C00000]"
                      : "var(--svgIconColor)"
                  } hover:cursor-pointer`}
                  onClick={handleSessionClick}
                >
                  <TooltipUi
                    name="Session"
                    icons={
                      // <Event
                      //   color={sessionHide ? "#C00000" : "var(--svgIconColor)"}
                      // />
                      <MdAssignment size={30} className="text-textColor" />
                    }
                    width="max"
                    placement="bottom"
                  />
                </span>
                <span
                  className={`${
                    sensorHide ? "border-b-[0.1px] border-[#C00000]" : ""
                  } hover:cursor-pointer`}
                  onClick={handleSensorClick}
                >
                  <TooltipUi
                    name="Sensor"
                    icons={
                      // <Sensor
                      //   color={sensorHide ? "#C00000" : "var(--svgIconColor)"}
                      // />
                      <MdSensors size={30} className="text-textColor" />
                    }
                    width="max"
                    placement="bottom"
                  />
                </span>
              </div>

              <div className="flex gap-[1px]">
                {/* <button onClick={StopReplay}>
                stop
              </button> */}
              </div>

              <div className="flex justify-center items-center">
                {HideUpperSecs ? (
                  <Tooltip title="Expand bottom panel">
                    <span
                      className="hover:cursor-pointer"
                      onClick={handleDeleteClick}
                    >
                      {/* <ExpandScreen /> */}
                      <FaExpand size={30} className="text-textColor" />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Resize bottom panel">
                    <span
                      className="hover:cursor-pointer"
                      onClick={handleDeleteClick}
                    >
                      {/* <ResizeScreen /> */}
                      <LuShrink size={30} className="text-textColor" />
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          )}
          {/* right section of the upper tab where we see the session names -- ends */}
        </Box>
        {/* first row ends */}

        {/* second row starts  */}
        {firstThreeSessionArray.length > 0 ? (
          <div>
            {sessionHide ? (
              <>
                {sessionData && firstThreeSessionArray.length > 0 && (
                  <div className="h-[100%] pb-10">
                    <div className="w-[100%] h-[100%] flex px-3 ">
                      <PanelGroup direction="horizontal">
                        <Panel defaultSize={50}>
                          <div className="w-[100%] h-[100%] ">
                            <div className="w-[100%] h-max flex gap-20 text-textColor pr-2 ">
                              <div className="flex justify-center items-center px-3">
                                <div>
                                  <p>Debriefing</p>
                                </div>
                              </div>
                              {sessionData?.sessionDebriefing?.debriefing && (
                                <div className="qs-speaker-container w-full flex gap-4 justify-end items-center">
                                  <Tooltip
                                    title="Test Your Knowledge"
                                    arrow
                                    placement="bottom"
                                  >
                                    <Button
                                      variant="contained"
                                      color="error"
                                      startIcon={
                                        <MdListAlt className="" size={30} />
                                      }
                                      sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                      }}
                                      onClick={handleTestKnowledgeClick}
                                    >
                                      Test
                                    </Button>
                                  </Tooltip>
                                  <div>
                                    {!btnHide ? (
                                      <Tooltip
                                        title="Listen to the debriefing"
                                        arrow
                                        placement="bottom"
                                      >
                                        {!isOnline ? (
                                          <Button
                                            variant="contained"
                                            disabled
                                            color="error"
                                            startIcon={
                                              <HiMiniSpeakerWave size={30} />
                                            }
                                            sx={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => textToSpeech("play")}
                                          >
                                            Listen
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            color="error"
                                            startIcon={
                                              <HiMiniSpeakerWave size={30} />
                                            }
                                            sx={{
                                              textTransform: "none",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() => textToSpeech("play")}
                                          >
                                            Listen
                                          </Button>
                                        )}
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Pause Audio"
                                        arrow
                                        placement="bottom"
                                      >
                                        <Button
                                          variant="contained"
                                          color="error"
                                          startIcon={
                                            <MdPauseCircle size={30} />
                                          }
                                          sx={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                          }}
                                          onClick={() => textToSpeech("stop")}
                                        >
                                          Pause
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="p-2">
                              {liveSessionState ? (
                                <div className="flex gap-2 items-center">
                                  <Debriefing />
                                  <p className="text-textColor pt-3 pl-2">
                                    Debriefing data is loading please wait....
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className={`for-debriefing pt-0  px-2  text-xs overflow-scroll scroll-smooth text-textColor `}
                                  style={{ height: `${screenHeight}px` }}
                                >
                                  {sessionData?.sessionDebriefing
                                    ?.debriefing ? (
                                    <>
                                      <div
                                        className="text-base"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            sessionData?.sessionDebriefing
                                              ?.debriefing,
                                        }}
                                      ></div>
                                    </>
                                  ) : macId ? (
                                    <div className="px-0 text-base">
                                      No debriefing data available. Manikin was
                                      not connected correctly at the time of
                                      operation..
                                    </div>
                                  ) : (
                                    <div className="text-base">
                                      Select a Manikin to view session
                                      debriefing.
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </Panel>

                        <PanelResizeHandle className="h-screen">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "2px",
                              height: "100%",
                              cursor: "ew-resize",
                              background: "var(--borderLeft)",
                            }}
                            className="relative"
                          >
                            <span className="absolute top-[15%]">
                              <div className="bg-[#bdbdbd] w-[15px] h-[40px] flex items-center justify-center rounded-md">
                                ||
                              </div>
                            </span>
                          </div>
                        </PanelResizeHandle>

                        <Panel defaultSize={50}>
                          <div className="w-[100%] h-[100%]  flex flex-col gap-[0.25rem] text-textColor">
                            {macId ? (
                              <>
                                <div className="flex justify-start px-2 items-center text-textColor text-md">
                                  {isRunning
                                    ? "Live Session"
                                    : `Significant moments of ${selectedSessionName}`}
                                </div>
                                <div
                                  className={` pb-6  ${
                                    HideUpperSecs
                                      ? "h-[300px]"
                                      : "h-[100vh] w-[100%]"
                                  }`}
                                >
                                  {listingMessage?.length > 0 ? (
                                    <LogTable listingMessage={listingMessage} />
                                  ) : (
                                    <div className="flex justify-start px-2 items-center">
                                      No logs found for this session...
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </Panel>
                      </PanelGroup>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            {/* sensor value */}
            {sensorHide ? (
              <CustomTabPanel value={value} index={1} name="Sensor">
                <div
                  ref={sensorDataRef}
                  className="flex flex-column justify-content-center sensor-data-list "
                >
                  {macId && sessionDataList?.length > 0 && (
                    <>
                      <div className="flex justify-center items-center text-textColor ">
                        Sensor Events for &nbsp;
                        {selectedSessionName}
                        {selectedSensorData.length > 0 && (
                          <CSVLink
                            data={selectedSensorData}
                            headers={SOCKET_DATA_HEADERS_CSV}
                            filename={`${
                              sessionData?.sessionDebriefing?.session_name ||
                              sessionData?.sessionDebriefing?.session_id
                            }.csv`}
                          >
                            <TooltipUi
                              name="Download Sensor Data"
                              icons={
                                <MdDownload
                                  color="var(--svgIconColor)"
                                  className="w-[40px] h-[40px]"
                                />
                              }
                              width="max"
                              placement="bottom"
                            />
                          </CSVLink>
                        )}
                      </div>

                      {!!selectedSensorData &&
                      selectedSensorData?.length > 0 ? (
                        <SensorData
                          selectedSensorData={selectedSensorData}
                          className="overflow-x-scroll"
                        />
                      ) : (
                        <div className="d-flex flex-row justify-content-center text-textColor py-6">
                          Sensor data not available
                        </div>
                      )}
                    </>
                  )}
                </div>
              </CustomTabPanel>
            ) : (
              " "
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center text-textColor ">
            Debriefing Panel
          </div>
        )}

        {/* second row ends  */}
      </div>

      {/* third row starts  */}
      <AssignTestModal
        heading={"Assign a Test"}
        handleClose={onClose}
        open={modalHide}
      >
        <div className="py-4 px-4 w-max h-max overflow-y-scroll">
          <div>
            <div className="flex gap-1.5 items-center">
              <div>
                <TestKnowledge />
              </div>
              <div className="text-[1.2rem] text-textColor font-medium">
                Test your Knowledge
              </div>
            </div>
          </div>

          <div className="py-4 flex flex-col gap-y-6">
            {sessionData?.question?.map((question) => (
              <div key={question.question_id} className="overflow-y-scroll">
                <div className="w-[25rem] text-textColor">
                  {question.question}
                </div>

                <div className="flex justify-start gap-2">
                  {question?.options?.map((option) => (
                    <div key={option?.option_id}>
                      <div
                        className={`w-max px-4 py-1.5 border-gray-300 border-[0.1px] cursor-pointer text-textColor ${
                          isOptionSelected(
                            question.question_id,
                            option.option_id
                          )
                            ? "bg-[#e9a135]"
                            : ""
                        } `}
                        onClick={() =>
                          handleOptionClick(
                            question.question_id,
                            option.option_id
                          )
                        }
                      >
                        {option.choices}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-[100%] flex justify-end py-2 gap-2 text-textColor">
                  {results[question.question_id] && (
                    <div className={`w-max`}>
                      {results[question.question_id].isCorrect ? (
                        <div className="px-2.5 py-1 rounded-full bg-green-600">
                          <GrCheckmark className="text-textColor" />
                        </div>
                      ) : (
                        <div className="px-2.5 rounded-full py-1 bg-red-400">
                          <GrClose className="text-textColor" />
                        </div>
                      )}
                    </div>
                  )}
                  {selectedOptions[question.question_id] && (
                    <p className="selectedOptionText">
                      {getOptionName(question.question_id)}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="w-100  flex justify-end items-center">
            <button
              className=" flex items-center px-2 bg-[var(--primary-color)]"
              onClick={handleSubmit}
            >
              <span>
                <SendIcon color="text-tectColor" />
              </span>
              <span className="text-[var(--text-primary-complementery)]">
                Show Result
              </span>
            </button>
          </div>
        </div>
      </AssignTestModal>

      <AssignTestModal heading={""} handleClose={onClose1} open={modalHide1}>
        <div className="basis-5/12 h-[100vh] ">
          <div className="w-[100%] h-max flex gap-20 pt-3 px-2 overflow-y-scroll">
            <p className="flex">
              <span>
                <DebriefingIcon />
              </span>{" "}
              <span> Debriefing </span>
            </p>

            <div className="flex gap-4">
              <span
                className="cursor-pointer"
                onClick={handleTestKnowledgeClick}
              >
                <TooltipUi
                  name="Test Knowledge"
                  icons={<TestKnowledge />}
                  width="max"
                />{" "}
              </span>
              <TooltipUi name="Note" icons={<ClinicalNotes />} width="max" />

              <span onClick={() => setModalHide1(false)}>
                <TooltipUi name="Restore" icons={<More />} width="max" />
              </span>
            </div>
          </div>

          <div className="pt-0 pb-12 w-[417px] h-[100vh] px-2 border-l-[0.1px] border-gray-200 text-xs overflow-y-scroll">
            {sessionData?.sessionDebriefing?.debriefing ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: sessionData?.sessionDebriefing?.debriefing,
                }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </AssignTestModal>
      {/* third row ends  */}
      {/* search modal is placed here */}
      {openSeachModal && (
        <div>
          {createPortal(
            <SessionsContainer
              firstThreeSessionArray={firstThreeSessionArray}
              setFirstThreeSessionArray={setFirstThreeSessionArray}
              list={allSessionsContainer}
              handleSessionSelection={handleSessionSelection}
              setOpenSearchModal={setOpenSearchModal}
              alreadySelectedSessionInd={alreadySelectedSessionInd}
              setAlreadySelectedSessionInd={setAlreadySelectedSessionInd}
            />,
            document.body
          )}
        </div>
      )}
    </>
  );
});

export default PlayLearnTabsComponent;
