const scenario = [
  {
    scenario: {
      patientDetails: {
        title: "Airway Obstruction in the ER for 40-Year-Old Man",
        name: "Ravi Kumar",
        age: 40,
        active: true,
        gender: "Male",
        weight: "85 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Diabetes Mellitus",
        },
      },
      presentation: {
        chiefComplaint:
          "Ravi Kumar, a 40-year-old male, presents to the emergency room after accidentally ingesting a piece of meat while eating dinner. He is experiencing persistent coughing, difficulty breathing, and mild cyanosis around his lips.",
        vitalSigns: {
          heartRate: "105 bpm",
          respiratoryRate: "26 breaths/min",
          oxygenSaturation: "90%",
          bloodPressure: "130/85 mmHg",
          description:
            "Ravi is alert but in moderate respiratory distress. He is coughing frequently and has audible stridor. He demonstrates mild cyanosis and has a low oxygen saturation level of 90%. Immediate management must be initiated to relieve the airway obstruction and maintain adequate oxygenation. The instructor should guide participants through the critical steps in the airway management process, considering the possibility of advancing to intubation if necessary.",
        },
        symptoms: [
          "Persistent coughing",
          "Difficulty breathing",
          "Mild cyanosis around lips",
        ],
      },
      management: {
        initialActions: [
          "Encourage Forceful Coughing: The patient is alert and able to follow commands. Instruct him to cough forcefully in an attempt to dislodge the obstruction",
          "Administer High-Flow Oxygen: Provide high-flow oxygen (10-15 L/min via face mask) to improve oxygen saturation. Monitor pulse oximeter readings to ensure that oxygen levels are maintained above 94%.",
        ],
        assessmentForObstructionType: [
          "Visual Assessment of Airway: Check the patient’s oral cavity for any visible foreign body. Instruct participants to inspect the throat and determine if the foreign body is visible and if there are signs of complete or partial obstruction.",
          "Assess Airway Patency: Evaluate the patient’s breathing and air exchange. Listen for any audible stridor or wheezing that might indicate airway narrowing or obstruction.",
        ],
        interventions: {
          nebulizedMedications: {
            medication: "Salbutamol (Albuterol) via nebulizer.",
            dose: "2.5 mg nebulized.",
          },
          intubation: {
            indication:
              "Use nebulized bronchodilators if wheezing or bronchospasm is detected, especially if the patient is showing signs of airway irritation or aspiration-induced bronchospasm.",
            ETTubeSize: "7.0 mm cuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If the patient’s respiratory distress worsens or if there is inadequate oxygenation despite initial management (coughing and oxygen administration), intubation may be necessary.",

            Procedure:
              "Demonstrate and assess participants’ ability to correctly prepare for intubation, securing the airway with an appropriate-sized ET tube.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Ensure continuous monitoring of oxygen levels via pulse oximetry, targeting a saturation of 94-98%.",
          MonitorforComplications:
            "Watch for signs of airway swelling, aspiration pneumonia, or worsening of respiratory distress.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Continue to provide supplemental oxygen as needed and reassess the patient regularly. If the patient was intubated, ensure proper ventilation and assess the effectiveness of sedation and pain management.",
          InvasiveMonitoring:
            "If required, use invasive monitoring like arterial blood gases (ABG) to assess acid-base balance and oxygenation levels in patients requiring advanced interventions.",
        },
        DischargeCriteria: {
          Stabilization:
            "Once the patient’s oxygen saturation normalizes, and the airway is clear, the patient can be considered for discharge. Ensure follow-up care, including advice to avoid choking hazards, and schedule a follow-up visit for further assessment.",
        },
        InstructorRole: {
          roleOne:
            "Provide feedback on the decision-making process, particularly in airway management and timely interventions.",
          roleTwo:
            "Prompt participants to consider the different management steps (initial, advanced, and post-intervention) and ask questions that guide their critical thinking.",
          roleThree:
            "Observe how participants respond to worsening respiratory distress, including how they make decisions about escalating interventions such as intubation.",
          roleFour:
            "Ensure participants are following the correct protocols for assessing and securing the airway.",
        },
        Conclusion: [
          "This simulation emphasizes the critical steps involved in the management of a patient with airway obstruction due to a foreign body aspiration in an emergency setting. Participants will practice assessing airway patency, administering high-flow oxygen, and intervening with appropriate medications. They will also develop skills in advanced airway management techniques, such as intubation, while monitoring for complications such as aspiration pneumonia and airway swelling.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Allergic Reaction in the ER for 40-Year-Old Man",
        name: "Amit Sharma",
        age: 40,
        active: false,
        gender: "Male",
        weight: "80 kg",
        medicalHistory: {
          knownAllergies: "Shellfish",
          chronicConditions: "Hypertension",
        },
      },
      presentation: {
        chiefComplaint:
          "Amit Sharma, a 40-year-old male, presents to the emergency room after accidentally consuming shellfish at a restaurant. He is experiencing rapid swelling of his lips and throat, hives all over his body, and difficulty breathing.",
        vitalSigns: {
          heartRate: "120 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "85%",
          bloodPressure: "90/60 mmHg",
          description:
            "Amit is conscious but in severe respiratory distress. He has noticeable swelling around his lips and neck, a diffuse rash, and audible wheezing. His oxygen saturation is dangerously low at 85%. Immediate administration of epinephrine and airway support is essential to manage the anaphylactic reaction and prevent further deterioration. The instructor should guide participants through emergency interventions and assess readiness for advanced airway procedures if needed.",
        },
        symptoms: [
          "Swelling of lips and throat",
          "Hives",
          "Wheezing",
          "Low blood pressure",
        ],
      },
      management: {
        initialActions: [
          "Administer Epinephrine: Inject 0.3 mg of epinephrine intramuscularly into the mid-outer thigh immediately. Repeat every 5-15 minutes if symptoms persist.",
          "Provide Oxygen Therapy: Start high-flow oxygen at 12-15 L/min through a non-rebreather mask to improve oxygenation.",
        ],
        assessmentForObstructionType: [
          "Check for Visible Swelling: Inspect the patient’s throat for signs of airway narrowing due to swelling.",
          "Assess Breathing: Listen for stridor or wheezing to evaluate the severity of airway compromise.",
        ],
        interventions: {
          nebulizedMedications: {
            medication:
              "Nebulized Ipratropium Bromide for severe bronchospasm.",
            dose: "500 mcg nebulized.",
          },
          intubation: {
            indication:
              "Consider intubation if swelling continues to progress despite epinephrine administration and oxygen therapy.",
            ETTubeSize: "6.5 mm cuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If the patient’s airway swelling becomes critical or oxygen levels remain below 85% despite initial interventions, prepare for emergency intubation to secure the airway.",
            Procedure:
              "Demonstrate preparation and technique for intubation, ensuring the correct tube size and rapid-sequence induction.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Maintain continuous monitoring of oxygen saturation, ensuring levels improve to at least 94%.",
          MonitorforComplications:
            "Watch for rebound anaphylaxis or worsening respiratory symptoms that may require further intervention.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Continue to monitor the patient’s breathing and provide oxygen as needed. Educate on allergen avoidance and provide a prescription for an epinephrine auto-injector.",
          InvasiveMonitoring:
            "Perform arterial blood gas analysis to check for hypoxemia and acid-base imbalance in critically ill patients.",
        },
        DischargeCriteria: {
          Stabilization:
            "Discharge the patient once oxygen saturation levels are stable, airway swelling has resolved, and there are no signs of rebound anaphylaxis. Provide follow-up care instructions.",
        },
        InstructorRole: {
          roleOne:
            "Evaluate participants’ ability to recognize the signs of anaphylaxis and initiate appropriate treatment quickly.",
          roleTwo:
            "Encourage participants to focus on decision-making for airway management and the use of advanced interventions when necessary.",
          roleThree:
            "Observe how participants handle the escalation of care, including preparation for intubation if required.",
          roleFour:
            "Ensure adherence to guidelines for epinephrine administration and anaphylaxis management.",
        },
        Conclusion: [
          "This simulation provides participants with experience in managing a patient with a severe allergic reaction. Key skills include administering epinephrine, managing airway swelling, and recognizing when advanced airway interventions are necessary. Participants will also learn to monitor for complications and provide education for future allergen avoidance.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Smoke Inhalation Injury in the ER for 38-Year-Old Man",
        name: "Rahul Verma",
        age: 38,
        active: false,
        gender: "Male",
        weight: "78 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Asthma",
        },
      },
      presentation: {
        chiefComplaint:
          "Rahul Verma, a 38-year-old male, presents to the emergency room after being rescued from a house fire. He complains of shortness of breath, persistent cough, and throat irritation.",
        vitalSigns: {
          heartRate: "112 bpm",
          respiratoryRate: "32 breaths/min",
          oxygenSaturation: "86%",
          bloodPressure: "140/90 mmHg",
          description:
            "Rahul is alert but in significant respiratory distress. He has soot around his nose and mouth, persistent coughing with blackened sputum, and audible wheezing. Oxygen saturation is critically low at 86%, and immediate intervention is necessary to improve oxygenation and evaluate for potential airway burns or carbon monoxide poisoning. The instructor should guide participants through oxygen therapy and advanced airway management as needed.",
        },
        symptoms: [
          "Shortness of breath",
          "Persistent cough with black sputum",
          "Throat irritation",
          "Low oxygen saturation",
        ],
      },
      management: {
        initialActions: [
          "Administer 100% Oxygen: Provide high-flow oxygen via a non-rebreather mask to improve oxygenation and reduce carbon monoxide levels in the blood.",
          "Monitor Carbon Monoxide Levels: Use a co-oximeter to assess carboxyhemoglobin levels and determine the severity of carbon monoxide poisoning.",
        ],
        assessmentForObstructionType: [
          "Inspect for Airway Burns: Look for signs of burns around the face, mouth, or inside the oral cavity.",
          "Evaluate Respiratory Effort: Listen for wheezing, stridor, or reduced breath sounds to assess airway patency.",
        ],
        interventions: {
          nebulizedMedications: {
            medication: "Nebulized Saline to loosen soot in airways.",
            dose: "5 mL nebulized.",
          },
          intubation: {
            indication:
              "Consider intubation if there are signs of airway edema, worsening respiratory distress, or if oxygen saturation remains below 90% despite oxygen therapy.",
            ETTubeSize: "8.0 mm cuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If airway swelling or respiratory failure occurs, prepare for intubation and mechanical ventilation to secure the airway and ensure adequate oxygenation.",
            Procedure:
              "Demonstrate the preparation and execution of intubation using rapid-sequence induction, ensuring the patient’s airway is protected from further injury.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Continuously monitor oxygen levels, ensuring they improve to at least 94% with treatment.",
          MonitorforComplications:
            "Watch for secondary complications, such as pneumonia, airway edema, or worsening hypoxemia.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Continue oxygen therapy as needed, and reassess airway patency. Provide supportive care and educate the patient about smoke inhalation recovery.",
          InvasiveMonitoring:
            "Perform arterial blood gas analysis to assess for hypoxemia, hypercapnia, and acid-base imbalance in severe cases.",
        },
        DischargeCriteria: {
          Stabilization:
            "The patient can be discharged once oxygen saturation normalizes, airway swelling resolves, and there are no signs of complications. Provide education on smoke exposure recovery and schedule a follow-up appointment.",
        },
        InstructorRole: {
          roleOne:
            "Evaluate participants’ ability to recognize signs of smoke inhalation injury and initiate appropriate interventions.",
          roleTwo:
            "Encourage participants to consider advanced airway management in cases of airway swelling or respiratory failure.",
          roleThree:
            "Assess the participants’ decision-making process regarding oxygen therapy and monitoring for carbon monoxide poisoning.",
          roleFour:
            "Ensure proper protocols are followed for airway evaluation and securing ventilation in critical cases.",
        },
        Conclusion: [
          "This simulation highlights the critical steps involved in managing a patient with smoke inhalation injury. Participants will practice oxygen therapy, airway assessment, and advanced airway management while monitoring for complications such as airway burns and carbon monoxide poisoning.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Anaphylaxis in the ER for 32-Year-Old Woman",
        name: "Meera Singh",
        age: 32,
        active: false,
        gender: "Female",
        weight: "65 kg",
        medicalHistory: {
          knownAllergies: "Bee stings",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "Meera Singh, a 32-year-old female, presents to the emergency room after being stung by a bee. She is experiencing rapid-onset swelling of her throat, difficulty breathing, and hives spreading across her body.",
        vitalSigns: {
          heartRate: "125 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "87%",
          bloodPressure: "85/55 mmHg",
          description:
            "Meera is conscious but in severe distress. She exhibits extensive facial and throat swelling, a widespread urticarial rash, and audible wheezing. Her oxygen saturation is critically low at 87%, and her blood pressure is dangerously low, indicating distributive shock. Immediate administration of epinephrine and airway support is required. The instructor should guide participants through emergency management and preparation for potential advanced airway interventions.",
        },
        symptoms: [
          "Throat swelling",
          "Difficulty breathing",
          "Widespread rash",
          "Low blood pressure",
        ],
      },
      management: {
        initialActions: [
          "Administer Epinephrine: Inject 0.3 mg of epinephrine intramuscularly into the mid-outer thigh immediately. Repeat every 5-15 minutes as needed based on response.",
          "Administer High-Flow Oxygen: Provide oxygen at 10-15 L/min via a non-rebreather mask to improve oxygenation and relieve respiratory distress.",
        ],
        assessmentForObstructionType: [
          "Assess Airway Swelling: Inspect the throat for significant edema that could compromise airway patency.",
          "Evaluate Respiratory Effort: Listen for wheezing or stridor to determine the severity of airway narrowing.",
        ],
        interventions: {
          nebulizedMedications: {
            medication:
              "Nebulized Ipratropium Bromide to relieve bronchospasm.",
            dose: "500 mcg nebulized.",
          },
          intubation: {
            indication:
              "Consider intubation if airway swelling progresses rapidly or if oxygen saturation remains below 90% despite initial interventions.",
            ETTubeSize: "7.0 mm cuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If throat swelling continues to worsen or respiratory failure occurs, prepare for emergency intubation to secure the airway and ensure ventilation.",
            Procedure:
              "Demonstrate rapid-sequence intubation, ensuring proper technique and timely action to prevent complete airway obstruction.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Continuously monitor oxygen saturation and aim to maintain levels above 94%.",
          MonitorforComplications:
            "Watch for rebound anaphylaxis, airway swelling, or signs of circulatory shock requiring further interventions.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Continue monitoring the patient closely, provide supplemental oxygen as needed, and educate on carrying an epinephrine auto-injector for future emergencies.",
          InvasiveMonitoring:
            "Perform arterial blood gas analysis to evaluate oxygenation and acid-base status in critically ill patients.",
        },
        DischargeCriteria: {
          Stabilization:
            "The patient can be discharged once symptoms resolve, oxygen saturation normalizes, and no signs of rebound anaphylaxis are present. Provide an anaphylaxis action plan and arrange follow-up care with an allergist.",
        },
        InstructorRole: {
          roleOne:
            "Assess participants’ ability to promptly recognize and treat severe anaphylaxis using epinephrine and other supportive measures.",
          roleTwo:
            "Guide participants in the decision-making process for advanced airway management in rapidly worsening cases.",
          roleThree:
            "Encourage discussion about recognizing early signs of shock and airway compromise.",
          roleFour:
            "Ensure adherence to emergency anaphylaxis treatment protocols and accurate medication dosing.",
        },
        Conclusion: [
          "This simulation focuses on the emergency management of severe anaphylaxis. Participants will learn to recognize life-threatening symptoms, administer epinephrine, manage airway compromise, and prepare for advanced interventions while monitoring for complications such as shock and airway obstruction.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Post-Surgical Airway Edema in the ER for 28-Year-Old Woman",
        name: "Anjali Rao",
        age: 28,
        active: false,
        gender: "Female",
        weight: "60 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "Anjali Rao, a 28-year-old female, presents to the emergency room with difficulty breathing and a muffled voice. She recently underwent thyroidectomy surgery and developed swelling in her throat post-operatively.",
        vitalSigns: {
          heartRate: "110 bpm",
          respiratoryRate: "24 breaths/min",
          oxygenSaturation: "89%",
          bloodPressure: "130/85 mmHg",
          description:
            "Anjali is alert but in significant respiratory distress. She exhibits visible neck swelling, stridor, and a hoarse, muffled voice. Oxygen saturation is critically low at 89%, indicating impaired airway patency. Immediate airway evaluation and management are necessary. The instructor should guide participants through assessing airway compromise and implementing interventions to prevent complete obstruction.",
        },
        symptoms: [
          "Difficulty breathing",
          "Neck swelling",
          "Stridor",
          "Muffled voice",
        ],
      },
      management: {
        initialActions: [
          "Administer High-Flow Oxygen: Provide oxygen via a non-rebreather mask at 10-15 L/min to improve oxygenation.",
          "Elevate the Head of Bed: Position the patient upright or at a 45-degree angle to reduce edema and improve breathing.",
        ],
        assessmentForObstructionType: [
          "Inspect for Signs of Airway Edema: Examine the neck and throat for swelling or redness.",
          "Assess for Stridor: Listen for inspiratory stridor and evaluate the severity of airway narrowing.",
        ],
        interventions: {
          nebulizedMedications: {
            medication: "Nebulized Epinephrine to reduce airway swelling.",
            dose: "5 mL of 1:1,000 solution nebulized.",
          },
          intubation: {
            indication:
              "Consider intubation if swelling progresses, stridor worsens, or oxygen saturation remains below 90% despite interventions.",
            ETTubeSize: "6.5 mm cuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If there is worsening respiratory distress or signs of imminent airway obstruction, proceed with emergency intubation to secure the airway.",
            Procedure:
              "Demonstrate rapid-sequence intubation techniques, ensuring timely intervention to prevent airway collapse.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Continuously monitor oxygen levels and aim to maintain saturation above 94%.",
          MonitorforComplications:
            "Watch for worsening edema, aspiration, or hypoxemia requiring further interventions.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Continue oxygen therapy, monitor for recurrence of symptoms, and educate the patient on recognizing warning signs of airway compromise.",
          InvasiveMonitoring:
            "Perform arterial blood gas analysis and evaluate the need for invasive monitoring based on respiratory status.",
        },
        DischargeCriteria: {
          Stabilization:
            "The patient can be discharged once airway swelling resolves, oxygen saturation normalizes, and no further signs of respiratory distress are observed. Provide follow-up care instructions, including avoiding strain on the surgical site.",
        },
        InstructorRole: {
          roleOne:
            "Guide participants in recognizing and managing airway edema, emphasizing rapid assessment and timely intervention.",
          roleTwo:
            "Encourage participants to evaluate the effectiveness of oxygen therapy and monitor for progression of airway compromise.",
          roleThree:
            "Assess participants’ preparedness for emergency airway management, including intubation and alternative interventions.",
          roleFour:
            "Ensure adherence to airway management protocols, including correct medication dosing and positioning techniques.",
        },
        Conclusion: [
          "This simulation focuses on the recognition and management of post-surgical airway edema. Participants will practice airway assessment, oxygen therapy, and advanced airway management while monitoring for complications such as complete airway obstruction and hypoxemia.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Foreign Body Aspiration in the ER for 5-Year-Old Girl",
        name: "Sara Ali",
        age: 5,
        active: false,
        gender: "Female",
        weight: "18 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Asthma (mild intermittent)",
        },
      },
      presentation: {
        chiefComplaint:
          "Sara Ali, a 5-year-old girl, was brought to the emergency room after choking on a small toy while playing. She is coughing persistently and showing signs of respiratory distress.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "92%",
          bloodPressure: "95/60 mmHg",
          description:
            "Sara appears anxious and is in moderate respiratory distress. She has a persistent, high-pitched cough and audible wheezing. Oxygen saturation is slightly reduced at 92%. Immediate assessment and intervention are necessary to prevent complete airway obstruction. The instructor should guide participants through the steps to relieve the obstruction and manage potential complications.",
        },
        symptoms: [
          "Persistent coughing",
          "Difficulty breathing",
          "Anxious appearance",
          "Audible wheezing",
        ],
      },
      management: {
        initialActions: [
          "Encourage Effective Coughing: If the child is coughing forcefully, allow her to continue as it may expel the foreign object.",
          "Administer Oxygen: Provide oxygen via a nasal cannula or mask to improve oxygen saturation and ease breathing.",
        ],
        assessmentForObstructionType: [
          "Visual Assessment: Inspect the mouth and throat for visible foreign objects without using invasive tools.",
          "Listen for Airflow Changes: Evaluate breathing sounds for stridor or diminished breath sounds indicating airway blockage.",
        ],
        interventions: {
          nebulizedMedications: {
            medication:
              "Nebulized Salbutamol to relieve airway spasm if present.",
            dose: "2.5 mg nebulized in normal saline.",
          },
          intubation: {
            indication:
              "Consider intubation if respiratory distress worsens or if complete airway obstruction is imminent.",
            ETTubeSize: "4.5 mm uncuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If the foreign object cannot be expelled and respiratory distress worsens, prepare for emergency intubation or bronchoscopy.",
            Procedure:
              "Demonstrate the steps of pediatric intubation and coordinate with the bronchoscopy team to remove the foreign object.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Continuously monitor oxygen levels and respiratory effort, aiming for saturation above 94%.",
          MonitorforComplications:
            "Watch for aspiration-induced pneumonia, laryngeal edema, or worsening respiratory distress.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Ensure the child’s breathing stabilizes and monitor for any lingering airway irritation or inflammation.",
          InvasiveMonitoring:
            "Consider chest X-rays or other imaging to confirm complete removal of the foreign object.",
        },
        DischargeCriteria: {
          Stabilization:
            "The child can be discharged once normal breathing is restored, oxygen saturation is stable, and imaging confirms the absence of residual foreign bodies. Provide caregiver education on choking prevention.",
        },
        InstructorRole: {
          roleOne:
            "Guide participants in recognizing partial versus complete airway obstruction in a pediatric patient.",
          roleTwo:
            "Encourage discussion of appropriate initial and advanced airway management techniques for children.",
          roleThree:
            "Assess participants’ ability to remain calm and take quick, decisive actions in an airway emergency.",
          roleFour:
            "Ensure adherence to pediatric advanced life support (PALS) protocols, including correct medication dosages and equipment sizes.",
        },
        Conclusion: [
          "This simulation focuses on the emergency management of foreign body aspiration in a pediatric patient. Participants will practice assessing airway patency, encouraging effective coughing, and preparing for advanced interventions such as intubation or bronchoscopy. They will also learn to monitor for complications and educate caregivers on prevention strategies.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Croup Management in the ER for 3-Year-Old Boy",
        name: "Arjun Mehta",
        age: 3,
        active: false,
        gender: "Male",
        weight: "14 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "Arjun Mehta, a 3-year-old boy, presents to the emergency room with a barking cough, difficulty breathing, and stridor. Symptoms started overnight and have progressively worsened.",
        vitalSigns: {
          heartRate: "120 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "94%",
          bloodPressure: "90/60 mmHg",
          description:
            "Arjun is alert but shows signs of respiratory distress. He has inspiratory stridor and a characteristic barking cough. His oxygen saturation is borderline at 94%. Immediate assessment and management are needed to reduce airway swelling and improve breathing. The instructor should guide participants through the assessment and interventions for croup management in a pediatric patient.",
        },
        symptoms: [
          "Barking cough",
          "Inspiratory stridor",
          "Respiratory distress",
          "Hoarseness",
        ],
      },
      management: {
        initialActions: [
          "Administer Humidified Oxygen: Provide humidified oxygen via face mask to ease breathing and soothe the airway.",
          "Calm the Child: Keep the child calm and avoid unnecessary procedures, as agitation can worsen airway obstruction.",
        ],
        assessmentForObstructionType: [
          "Auscultate the Chest: Listen for stridor, wheezing, or diminished air entry.",
          "Visual Assessment: Observe for signs of severe distress, such as retractions or cyanosis.",
        ],
        interventions: {
          nebulizedMedications: {
            medication: "Nebulized Epinephrine for severe croup symptoms.",
            dose: "5 mL of 1:1,000 solution nebulized.",
          },
          intubation: {
            indication:
              "Consider intubation if stridor worsens, oxygen saturation drops below 90%, or there are signs of impending airway collapse.",
            ETTubeSize: "4.0 mm uncuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "If the airway obstruction progresses or there is no response to nebulized epinephrine and steroids, prepare for emergency intubation.",
            Procedure:
              "Demonstrate rapid-sequence intubation in a pediatric patient while minimizing trauma to the airway.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Continuously monitor oxygen saturation and respiratory rate, aiming for saturation above 94%.",
          MonitorforComplications:
            "Watch for signs of worsening airway obstruction or side effects from nebulized medications.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Continue to monitor the child’s breathing, administer steroids as prescribed, and provide reassurance to caregivers.",
          InvasiveMonitoring:
            "Perform imaging or laryngoscopy if symptoms persist to assess airway anatomy and rule out other causes.",
        },
        DischargeCriteria: {
          Stabilization:
            "The child can be discharged once stridor resolves, breathing normalizes, and oxygen saturation is stable. Educate caregivers on recognizing early signs of recurrence and when to seek immediate medical attention.",
        },
        InstructorRole: {
          roleOne:
            "Guide participants in identifying the severity of croup and selecting appropriate management strategies.",
          roleTwo:
            "Encourage discussion about the indications for nebulized epinephrine and corticosteroid therapy.",
          roleThree:
            "Assess participants’ ability to manage pediatric airway emergencies while keeping the child calm.",
          roleFour:
            "Ensure adherence to pediatric airway management protocols, including correct medication doses and equipment sizes.",
        },
        Conclusion: [
          "This simulation emphasizes the management of croup in a pediatric patient. Participants will practice airway assessment, use of nebulized epinephrine, and the decision-making process for advanced interventions. They will also learn to educate caregivers about the condition and its management.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title:
          "Airway Compromise from Infection in the ER for 40-Year-Old Woman",
        name: "Sophia Patel",
        age: 40,
        active: false,
        gender: "Female",
        weight: "70 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Type 2 Diabetes, Hypertension",
        },
      },
      presentation: {
        chiefComplaint:
          "Sophia Patel, a 40-year-old woman, presents to the emergency room with severe sore throat, fever, difficulty breathing, and drooling. Symptoms started two days ago with a mild sore throat but have progressively worsened.",
        vitalSigns: {
          heartRate: "110 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "88%",
          bloodPressure: "130/85 mmHg",
          description:
            "Sophia is anxious and in moderate distress. She is unable to swallow saliva without difficulty, and her voice is muffled. Physical examination reveals signs of airway swelling, likely caused by an infectious process. Immediate intervention is required to manage the airway and treat the underlying infection.",
        },
        symptoms: [
          "Severe sore throat",
          "Difficulty swallowing",
          "Drooling",
          "Muffled voice (hot potato voice)",
          "Fever",
          "Shortness of breath",
        ],
      },
      management: {
        initialActions: [
          "Administer Oxygen: Provide supplemental oxygen via face mask to maintain oxygen saturation above 92%.",
          "Position the Patient Upright: Position the patient in a sitting position to help reduce airway obstruction.",
        ],
        assessmentForObstructionType: [
          "Auscultate the Chest: Listen for stridor or signs of respiratory distress indicating partial airway obstruction.",
          "Examine the Airway: Inspect the throat for visible signs of swelling, abscess formation, or signs of infection like erythema and pus.",
        ],
        interventions: {
          nebulizedMedications: {
            medication:
              "Nebulized epinephrine to reduce airway swelling and improve breathing.",
            dose: "5 mL of 1:1,000 epinephrine solution nebulized every 20 minutes as needed.",
          },
          intubation: {
            indication:
              "If respiratory distress worsens, or the airway becomes compromised despite initial interventions, intubation should be considered.",
            ETTubeSize: "7.0 mm cuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "Prepare for intubation if the patient's condition worsens, including worsening stridor, respiratory fatigue, or inability to maintain oxygenation.",
            Procedure:
              "Perform endotracheal intubation if airway obstruction is severe, ensuring proper sedation and analgesia during the procedure. Alternatively, consider fiberoptic intubation if needed.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Monitor oxygen saturation closely with a pulse oximeter, ensuring levels remain above 92%.",
          MonitorforComplications:
            "Watch for worsening airway obstruction, signs of respiratory failure, or complications like aspiration pneumonia or sepsis.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Administer intravenous antibiotics to treat the underlying infection. Continue to monitor the airway closely for any signs of recurrence.",
          InvasiveMonitoring:
            "If intubation is performed, consider using invasive monitoring such as arterial blood gases (ABG) to assess oxygenation and ventilation status.",
        },
        DischargeCriteria: {
          Stabilization:
            "Discharge when oxygen levels stabilize above 92%, and the patient is able to swallow and speak without distress. Ensure appropriate follow-up care for the infection, and provide education on the need for completing the prescribed antibiotic regimen.",
        },
        InstructorRole: {
          roleOne:
            "Guide participants in recognizing signs of airway compromise from infection and the urgency of early intervention.",
          roleTwo:
            "Encourage discussions on the appropriate use of nebulized medications, airway management techniques, and infection control.",
          roleThree:
            "Assess participants’ ability to manage airway interventions, including intubation and sedation, in a patient with compromised airway.",
          roleFour:
            "Ensure participants understand the potential complications, including the risk of aspiration and the need for timely antibiotics.",
        },
        Conclusion: [
          "This simulation highlights the critical management of airway compromise caused by infection. Participants will practice assessing and securing the airway, administering medications, and managing complications such as infection and respiratory failure. They will also learn about the importance of antibiotics and post-intervention care in preventing recurrence of airway obstruction.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Asthma Attack in the ER for 7-Year-Old Girl",
        name: "Maya Verma",
        age: 7,
        active: false,
        gender: "Female",
        weight: "25 kg",
        medicalHistory: {
          knownAllergies: "Peanuts",
          chronicConditions:
            "Asthma (previous hospitalizations for exacerbations)",
        },
      },
      presentation: {
        chiefComplaint:
          "Maya Verma, a 7-year-old girl, presents to the emergency room with difficulty breathing, wheezing, and a persistent cough. Her symptoms worsened after exposure to allergens at school.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "89%",
          bloodPressure: "110/70 mmHg",
          description:
            "Maya is visibly distressed and unable to speak in full sentences due to severe shortness of breath. She has audible wheezing, and her oxygen saturation is low at 89%. Immediate treatment is required to relieve bronchospasm and improve oxygenation. The instructor should guide participants in the use of medications, monitoring, and airway management.",
        },
        symptoms: [
          "Severe shortness of breath",
          "Wheezing",
          "Persistent cough",
          "Inability to complete sentences",
          "Low oxygen saturation",
        ],
      },
      management: {
        initialActions: [
          "Administer Oxygen: Provide supplemental oxygen via face mask to improve oxygen saturation to at least 92%.",
          "Position the Patient Upright: Encourage the patient to sit up in a comfortable position to optimize airflow.",
        ],
        assessmentForObstructionType: [
          "Auscultate the Chest: Listen for wheezing, especially during exhalation, and assess for any silent chest (which may indicate severe obstruction).",
          "Assess Breathing Effort: Observe for signs of increased work of breathing, such as use of accessory muscles and nasal flaring.",
        ],
        interventions: {
          nebulizedMedications: {
            medication: "Albuterol nebulized for rapid bronchodilation.",
            dose: "2.5 mg via nebulizer every 20 minutes as needed.",
          },
          intubation: {
            indication:
              "If respiratory distress persists or oxygen saturation remains below 90% despite initial treatments, intubation may be necessary.",
            ETTubeSize: "6.0 mm uncuffed endotracheal tube.",
          },
        },
        advancedInterventions: {
          Anintubation: {
            indication2:
              "Prepare for intubation if the patient shows signs of fatigue, worsening hypoxia, or inability to maintain adequate ventilation.",
            Procedure:
              "Demonstrate rapid-sequence intubation with appropriate medication to sedate and paralyze the child. Ensure proper ventilation post-intubation.",
          },
        },
        MonitoringAndFollowUp: {
          MonitorOxygenSaturation:
            "Continuously monitor oxygen saturation and respiratory rate, aiming for saturation above 92%.",
          MonitorforComplications:
            "Watch for signs of worsening respiratory distress, pneumothorax, or side effects from medication, such as tachycardia.",
        },
        PostInterventionCare: {
          IfObstructionisRelieved:
            "Once asthma symptoms improve, continue with inhaled corticosteroids and bronchodilators as prescribed. Monitor the patient closely for signs of relapse.",
          InvasiveMonitoring:
            "If intubation is performed, monitor for complications such as ventilator-associated pneumonia and ensure adequate sedation and pain management.",
        },
        DischargeCriteria: {
          Stabilization:
            "The patient can be discharged once oxygen saturation stabilizes above 92%, wheezing resolves, and the patient can speak in full sentences. Ensure follow-up care with the pediatric pulmonologist and review asthma management plan.",
        },
        InstructorRole: {
          roleOne:
            "Guide participants through recognizing the signs of severe asthma exacerbation and selecting appropriate medications and interventions.",
          roleTwo:
            "Encourage participants to consider the risks of worsening asthma, including the potential need for intubation, and help them prepare for advanced interventions.",
          roleThree:
            "Assess participants' ability to manage the pediatric airway and ventilatory support in an acute asthma attack.",
          roleFour:
            "Ensure participants adhere to asthma management guidelines and protocols for medication use and monitoring.",
        },
        Conclusion: [
          "This simulation emphasizes the rapid management of a severe asthma exacerbation in a pediatric patient. Participants will practice assessing airway patency, administering bronchodilators, and providing appropriate respiratory support. They will also learn how to prevent respiratory failure and educate caregivers on proper asthma management and follow-up.",
        ],
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Burn Airway Injury in the ER for 8-Year-Old Boy",
        name: "Ethan Wilson",
        age: 8,
        active: false,
        gender: "Male",
        weight: "30 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, an 8-year-old boy, presented to the ER after a house fire with signs of airway burns, including hoarseness, stridor, and facial burns. He was in severe respiratory distress and had soot in the oropharynx.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "36 breaths/min",
          oxygenSaturation: "86%",
          bloodPressure: "100/60 mmHg",
        },
        symptoms: ["Hoarseness", "Stridor", "Facial burns"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway swelling from thermal injury",
            ETTubeSize: "5.5 mm (cuffed)",
          },
        },
      },
    },
  },
];

export default scenario;
