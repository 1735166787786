import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const ForTesting = ({
  width = 800,
  height = 400,
  color = "steelblue",
  stroke = 2,
}) => {
  const svgRef = useRef(null);
  const dataset = [
    { x: 0, y: 0 },
    { x: 10, y: 6 },
    { x: 20, y: 9 },
    { x: 30, y: 6 },
    { x: 40, y: 0 },
    { x: 50, y: 0 },
    { x: 60, y: -3 },
    { x: 70, y: 17 },
    { x: 80, y: 95 },
    { x: 90, y: 17 },
    { x: 100, y: -29 },
    { x: 110, y: 0 },
    { x: 120, y: 0 },
    { x: 130, y: 4 },
    { x: 140, y: 9 },
    { x: 150, y: 16 },
    { x: 160, y: 21 },
    { x: 170, y: 16 },
    { x: 180, y: 9 },
    { x: 190, y: 4 },
    { x: 200, y: 0 },
    { x: 210, y: 0 },
    { x: 220, y: 6 },
    { x: 230, y: 9 },
    { x: 240, y: 6 },
    { x: 250, y: 0 },
    { x: 260, y: 0 },
    { x: 270, y: -3 },
    { x: 280, y: 47 },
    { x: 290, y: 94 },
    { x: 300, y: 47 },
    { x: 310, y: -9 },
  ];
  ;
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous content

    // Define scales
    const xScale = d3
      .scaleLinear()
      .domain([d3.min(dataset, (d) => d.x), d3.max(dataset, (d) => d.x)])
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(dataset, (d) => d.y), d3.max(dataset, (d) => d.y)])
      .range([height, 0]);

    // Define the line generator
    const line = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveLinear); // Use linear for straight lines, change to curveBasis/curveNatural for smooth curves

    // Add the line path
    svg
      .append("path")
      .datum(dataset)
      .attr("fill", "none")
      .attr("stroke", color)
      .attr("stroke-width", stroke)
      .attr("d", line);

    // Add X axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // Add Y axis
    svg.append("g").call(d3.axisLeft(yScale));
  }, [dataset, width, height, color, stroke]);

  return <svg ref={svgRef} className="graph-container" ></svg>;
};

export default ForTesting;

