import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ProgressBar from "../../components/Student-sec/progressbar/ProgressBar";

const ProgresBar = ({
  sessionDuration,
  currentTime,
  listingMessage,
  onClickSeeker,
}) => {
  const [seconds, setSeconds] = useState(parseInt(currentTime / 1000)); // this value is to use for progressbar progression
  const [currentTimeStamp, setCurrentTimeStamp] = useState(0); // this value shows the progressing time in timer
  const [totalTime, setTotalTime] = useState(0); // this value is to show total time duration for the replay
  function convertToMinutesAndSeconds(totalMilliSeconds) {
    const minutes = Math.floor(totalMilliSeconds / 1000 / 60);
    const seconds = totalMilliSeconds % 60;
    return `${minutes}m : ${seconds}s`;
  }
  useEffect(() => {
    let getTotalTime = convertToMinutesAndSeconds(parseInt(sessionDuration));
    setTotalTime(getTotalTime);
  }, [sessionDuration]);
  useEffect(() => {
    setSeconds(parseInt(currentTime / 1000));
    let getCurrentTime = convertToMinutesAndSeconds(
      parseInt(currentTime / 1000)
    );
    setCurrentTimeStamp(getCurrentTime);
  }, [currentTime]);
  return (
    // <progress value={seconds} max={sessionDuration} />
    <>
      {listingMessage.length > 0 && (
        <div className="flex items-center justify-around gap-2">
          <ProgressBar value={seconds} max={sessionDuration} />
          <div className="whitespace-nowrap text-textColor text-sm font-thin pl-5 ">
            <span>{currentTimeStamp}</span> / <span>{totalTime}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgresBar;
