import { Height } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

function GraphData({ color_code, category, value, mapValue }) {
  const  [height, setHeight] = useState(window.innerHeight * 0.8 * 0.2)
  useEffect(() => {
   setHeight( document.querySelector(".graph-data-card").clientHeight / 4)
  }, [])
  useEffect(() => {

  }, [value]);
  return (
    <div style={{height: `${height - 2}px`}} className={` bg-[#202020] w-40   flex flex-col justify-around items-center  border-[#444444] border-b-[1px] `}>
      <div className="relative">
        <div
          className=" text-base font-thin text-left"
          style={{ color: color_code }}
        >
          {category}
        </div>
      </div>

      {mapValue !== undefined ? (
        <>
          <div
            className="sm:text-[1.75rem] lg:text-[2rem]"
            style={{ color: color_code }}
          >
            {value}
          </div>
          <div className="text-sm" style={{ color: color_code }}>
            MAP = {mapValue}
          </div>
        </>
      ) : (
        <div
          className="sm:text-[1.75rem] font-bold lg:text-[2rem]"
          style={{ color: color_code }}
        >
          {value}
        </div>
      )}
    </div>
  );
}

export default GraphData;
