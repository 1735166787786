var eventMetaData = [
  { event: 1, eventName: "HEAD_TILTED_ON", alertId: 1, actions: "animate" },
  { event: 2, eventName: "HEAD_TILTED_OFF", alertId: 0, actions: "animate" },
  { event: 3, eventName: "TOOTH_PRESSED_HARD", alertId: 4, actions: "" },
  { event: 4, eventName: "OESOPHAGAL_ENTRY", alertId: 0, actions: "" },
  { event: 5, eventName: "OESOPHAGAL_EXIT", alertId: 0, actions: "" },
  { event: 6, eventName: "TRACHEAL_ENTRY", alertId: 0, actions: "" },
  { event: 7, eventName: "TRACHEAL_EXIT", alertId: 0, actions: "" },
  { event: 8, eventName: "BEFORE:OE1", alertId: 0, actions: "animate" },
  { event: 9, eventName: "AFTER:OE1", alertId: 5, actions: "animate" },
  { event: 10, eventName: "BEFORE:OE2", alertId: 0, actions: "animate" },
  { event: 11, eventName: "AFTER:OE2", alertId: 0, actions: "animate" },
  { event: 12, eventName: "BETWEEN:OE1:OE2", alertId: 0, actions: "animate" },
  { event: 13, eventName: "BEFORE:TRS", alertId: 0, actions: "animate" },
  { event: 14, eventName: "AFTER:TRS", alertId: 6, actions: "animate" },
  { event: 15, eventName: "BEFORE:TR1", alertId: 0, actions: "animate" },
  { event: 16, eventName: "AFTER:TR1", alertId: 0, actions: "animate" },
  { event: 17, eventName: "BETWEEN:TRS:TR1", alertId: 0, actions: "animate" },
  { event: 18, eventName: "BEFORE:TR2", alertId: 0, actions: "animate" },
  { event: 19, eventName: "AFTER:TR2", alertId: 0, actions: "animate" },
  { event: 20, eventName: "BETWEEN:TR1:TR2", alertId: 0, actions: "animate" },
  { event: 21, eventName: "BEFORE:TRE", alertId: 0, actions: "animate" },
  { event: 22, eventName: "AFTER:TRE", alertId: 7, actions: "animate" },
  { event: 23, eventName: "BETWEEN:TR2:TRE", alertId: 0, actions: "animate" },
  { event: 24, eventName: "TRW_ON", alertId: 8, actions: "animate" },
  { event: 25, eventName: "TRW_OFF", alertId: 0, actions: "" },
  { event: 26, eventName: "LNL_ON", alertId: 9, actions: "animate" },
  { event: 27, eventName: "LNL_OFF", alertId: 0, actions: "" },
  { event: 28, eventName: "LNR_ON", alertId: 10, actions: "animate" },
  { event: 29, eventName: "LNR_OFF", alertId: 0, actions: "" },
  { event: 30, eventName: "INGRESSING", alertId: 0, actions: "" },
  { event: 31, eventName: "EGRESSING", alertId: 0, actions: "" },
  { event: 32, eventName: "SESSION_ON", alertId: 0, actions: "" },
  { event: 33, eventName: "SESSION_OFF", alertId: 0, actions: "" },
  { event: 99, eventName: "CROSSTALK", alertId: 99, actions: "" },
  { event: 100, eventName: "UNKNOWN_EXCEPTION", alertId: 100, actions: "" },
  {
    event: 201,
    eventName: "HEAD_TILT_SENSOR_NOT_DETECTED",
    alertId: 0,
    actions: "",
  },
  {
    event: 202,
    eventName: "TOOTH_SENSOR_NOT_DETECTED",
    alertId: 0,
    actions: "",
  },
  { event: 203, eventName: "OE1_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 204, eventName: "OE2_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 205, eventName: "TRS_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 206, eventName: "TR1_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 207, eventName: "TR2_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 208, eventName: "TRE_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 209, eventName: "TRW_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 210, eventName: "LNL_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 211, eventName: "LNR_SENSOR_NOT_DETECTED", alertId: 0, actions: "" },
  { event: 211, eventName: "SENSOR_NOT_RESPONDING", alertId: 0, actions: "" },
];

const alertMetaData = [
  {
    alert_id: 1,
    alert_name: "Head tilted",
    level: "INFO",
    av_type: "AV",
    background: "#FAF02A",
    "text-color": "#000000",
  },
  {
    alert_id: 2,
    alert_name: "Head Tilt not detected",
    level: "FINE",
    av_type: "",
    background: "",
    "text-color": "",
  },
  {
    alert_id: 3,
    alert_name: "Heaad tilted despite head or neck injury",
    level: "ERROR",
    av_type: "AV",
    background: "#8B0000",
    "text-color": "#FFFFFF",
  },
  {
    alert_id: 4,
    alert_name: "Excessive tooth pressure",
    level: "ERROR",
    av_type: "AV",
    background: "#8B0000",
    "text-color": "#FFFFFF",
  },
  {
    alert_id: 5,
    alert_name: "Oesophegal entry",
    level: "ERROR",
    av_type: "AV",
    background: "#8B0000",
    "text-color": "#FFFFFF",
  },
  {
    alert_id: 6,
    alert_name: "Entered Trachea",
    level: "FINE",
    av_type: "V",
    background: "",
    "text-color": "",
  },
  {
    alert_id: 7,
    alert_name: "Inserted too deep in Trachea",
    level: "ERROR",
    av_type: "AV",
    background: "#8B0000",
    "text-color": "#FFFFFF",
  },
  {
    alert_id: 8,
    alert_name: "Cuff Inflated",
    level: "INFO",
    av_type: "AV",
    background: "#FFFF00",
    "text-color": "#000000",
  },
  {
    alert_id: 9,
    alert_name: "Left lung inflated",
    level: "INFO",
    av_type: "AV",
    background: "#FFFF00",
    "text-color": "#000000",
  },
  {
    alert_id: 10,
    alert_name: "Right Lung infalted",
    level: "INFO",
    av_type: "AV",
    background: "#FFFF00",
    "text-color": "#000000",
  },
  {
    alert_id: 100,
    alert_name: "System Error",
    level: "SYSTEM ERROR",
    av_type: "AV",
    background: "#FF0000",
    "text-color": "#FFFF00",
  },
  {
    alert_id: 99,
    alert_name: "Cross Talk",
    level: "SYSERROR",
    av_type: "AV",
    background: "#FF0000",
    "text-color": "#FFFF00",
  },
];

export {eventMetaData,alertMetaData};