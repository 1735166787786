import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthContainer from "./components/common/auth-container";
import AdminContainer from "./components/common/admin-container";
import Dashboard from "./screen/admin-sec/sys-admin/dashboard";
import UserSetup from "./screen/admin-sec/User-setup";
import GetHelp from "./screen/admin-sec/Get-help";
import WatchLearn from "./screen/student-sec/Watch-learn";
import PlayAndLearn from "./screen/student-sec/playAndLearn";

import SysAdminLogin from "./screen/auth/sys_admin_login";
// import Organization from "./screen/admin-sec/sys-admin/organization/organization";
import Users from "./screen/admin-sec/sys-admin/users";
import Manikin from "./screen/admin-sec/sys-admin/Manikin";
import Mapping from "./screen/admin-sec/sys-admin/Mapping";
import Toastr from "./components/toastr";
import { useDispatch, useSelector } from "react-redux";
import NotificationPanel from "./components/notification-panel/notification-panel";
import { toggleNotificationPanel } from "./store/global.slice";
import UserTypesComponent from "./screen/admin-sec/sys-admin/user-types";
import DeviceTypesComponent from "./screen/admin-sec/sys-admin/devices/device-types/device-types";
import DeviceSubTypesComponent from "./screen/admin-sec/sys-admin/devices/device-sub-types/device-sub-types";
import SensorLocationsComponent from "./screen/admin-sec/sys-admin/devices/sensor-locations/sensor-locations";
import ManikinDetailsComponent from "./screen/admin-sec/sys-admin/manikin-details/manikin-details";
import SignIn from "./screen/auth/SignIn";
import Login from "./screen/auth/login";
import AssignedtTest from "./screen/student-sec/AssignedTest";
import Student from "./screen/Instructor-sec/Instructor";
import AssignMentList from "./screen/Instructor-sec/AssignMentList";
import AssignedAllTestData from "./screen/student-sec/AssignedAllTestData";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import TestSessionComp from "./screen/student-sec/TestSessionComp";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Card,
  CardContent,
  CardMedia,
  Switch,
  Typography,
} from "@mui/material";
import InstructorList from "./components/orgAdmin/InstructorList";
import ManikinList from "./components/orgAdmin/ManikinList";
import OrgAdminList from "./components/superAdmin/OrgAdminList";
import Organization from "./components/superAdmin/Organization";
import Auth from "./components/auth";
import ErrorCodes from "./screen/student-sec/ErrorCodes";
import { store } from "./store/store";
import { Provider } from "react-redux";
import VitalMonitor from "./screen/student-sec/Graphs/VitalMonitor";
import ForTesting from "./screen/student-sec/ForTesting";
import Impersonation from "./components/superAdmin/Impersonation";
function App() {
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const isAlertVisible = useSelector((state) => state.alert.isVisible);
  const isNotifyPanelVisible = useSelector(
    (state) => state.global.showNotificationPanel
  );
  const dispatch = useDispatch();
  const handleNotfPanelClose = () => {
    dispatch(toggleNotificationPanel());
  };

  const [toggleDarkMode, setToggleDarkMode] = useState(true);

  // function to toggle the dark mode as true or false

  // applying the primary and secondary theme colors
  const darkTheme = createTheme({
    palette: {
      mode: toggleDarkMode ? "dark" : "light", // handle the dark mode state on toggle
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#131052",
      },
    },
  });

  const toogleSwitch = (switch1) => {
    setToggleDarkMode(switch1);
  };

  return (
    <>
      {/* <ThemeProvider theme={darkTheme}>
      <CssBaseline /> */}
      <ToastContainer autoClose={2000} limit={1} />
      {isAlertVisible ? <Toastr /> : null}
      {!token ? (
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<AuthContainer />}>
              <Route index element={<Navigate to="login-sys" />} />
              <Route path="login-sys" element={<SysAdminLogin />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </Provider>
      ) : (
        <>
          <NotificationPanel
            show={isNotifyPanelVisible}
            onClose={handleNotfPanelClose}
          />
          <Routes>
            <Route
              path="/"
              element={<AdminContainer toogleSwitch={toogleSwitch} />}
            >
              <Route
                index
                element={
                  userType === "user" ? (
                    <Navigate to="playAndLearn" />
                  ) : userType === "admin" ? (
                    <Navigate to="instructor" />
                  ) : userType === "instructor" ? (
                    <Navigate to="student" />
                  ) : (
                    <Navigate to="organization" />
                  )
                }
              />

              <Route element={<Auth allowedRoles={["instructor", "admin"]} />}>
                <Route path="student" element={<Student />} />
              </Route>

              <Route element={<Auth allowedRoles={["instructor"]} />}>
                <Route path="assignment" element={<AssignMentList />} />
              </Route>

              {/* <Route path="dashboard" element={<Dashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="device-types" element={<DeviceTypesComponent />} />
              <Route path="device-sub-types" element={<DeviceSubTypesComponent />} />
              <Route path="sensor-locations" element={<SensorLocationsComponent />} />
              <Route path="mapping" element={<Mapping />} />
              <Route path="userSetup" element={<UserSetup />} />
              <Route path="user-types" element={<UserTypesComponent />} /> */}
              {/* <Route path="manikin/:id" element={<ManikinDetailsComponent />} /> */}

              <Route element={<Auth allowedRoles={["user"]} />}>
                <Route path="assignedtest" element={<AssignedtTest />} />
              </Route>

              <Route element={<Auth allowedRoles={["user", "instructor"]} />}>
                <Route
                  path="assignmentList"
                  element={<AssignedAllTestData />}
                />
                <Route path="getHelp" element={<GetHelp />} />
                <Route path="playAndLearn" element={<PlayAndLearn />} />
                <Route path="watchLearn" element={<WatchLearn />} />
                <Route path="session" element={<TestSessionComp />} />
                <Route path="graphs" element={<ForTesting />} />
              </Route>

              <Route element={<Auth allowedRoles={["admin"]} />}>
                <Route path="instructor" element={<InstructorList />} />
              </Route>

              {/* <Route path="manikinList" element={<ManikinList/>}/> */}
              {/* <Route path='orgadminlist' element={<OrgAdminList/>} /> */}

              <Route
                element={
                  <Auth allowedRoles={["root", "admin", "instructor"]} />
                }
              >
                <Route path="manikin" element={<ManikinList />} />
                <Route path="organization" element={<Organization />} />
                <Route path="sysadmin" element={<Impersonation />} />

                <Route path="errorcode" element={<ErrorCodes />} />
              </Route>

              <Route element={<Auth allowedRoles={["root", "admin"]} />}>
                <Route
                  path="organization/:id/manikin"
                  element={<ManikinList />}
                />
              </Route>

              {/* <Route path="*" element={<Navigate to="/" />} /> */}
            </Route>
          </Routes>
        </>
      )}
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
