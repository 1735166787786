import React, { useEffect, useState } from "react";
import logo from "../../assets/images/login/logo_new.png";
import logo2 from "../../assets/images/login/logo2_new.png";
import CustomModal from "../common/CustomModal";
import AssignmentAdd from "../icons/AssignmentAdd";
import RightArrow from "../icons/RightArrow";
import { getUsersByType } from "../../api/user.rest";
import AssignTestModal from "../common/AssignTestModal";
import { toast } from "react-toastify";
import TooltipUi from "../common/TooltipUi";
import { getOrganizations } from "../../api/org.rest";
import { createOrganization } from "../../api/org.rest";
import { Link, useNavigate } from "react-router-dom";
import { IoSettings } from "react-icons/io5";
import { updateOrganization } from "../../api/org.rest";
import { newUserCreate } from "../../api/user.rest";
import { IoMdAdd } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { GiInfo } from "react-icons/gi";

const Organization = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const [modalHide, setModalHide] = useState(false);
  const [modalHide1, setModalHide1] = useState(false);
  const [modalHide2, setModalHide2] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const orgId = localStorage.getItem("orgId");
  const [orgNameErrMsg1, setOrgNameErrMsg1] = useState("");
  const [orgAddressErrMsg1, setorgAddressErrMsg1] = useState("");
  const [orgTypeErrMsg1, setorgTypeErrMsg1] = useState("");
  const [orgRegDateErrMsg1, setorgRegDateErrMsg1] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [orgType, setOrgType] = useState("");
  const [orgRegDate, setOrgRegDate] = useState(currentDate);
  const [EditOrgId, setEditOrgId] = useState("");
  const [updateOrganization1, setUpdateOrganization1] = useState({
    org_nm: "",
    org_add: "",
    org_type: "",
    org_regdate: "",
  });

  const [uNameErrMsg, setUNameErrMsg] = useState("");
  const [unmErrMsg, setUnmErrMsg] = useState("");
  const [pswErrMsg, setPswErrMsg] = useState("");
  const [addErrMsg, setAdderrMsg] = useState("");
  const [mobErrMsg, setMobErrMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [unm, setUnm] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAdderss] = useState("");
  const [mobNumber, setMobNumber] = useState("");
  const [adminOrgId, setAdminOrgId] = useState("");

  const getAllOrg = async () => {
    await getOrganizations()
      .then((resp) => {
        setOrgData(resp?.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    getAllOrg();
  }, []);

  const onClose = () => {
    setModalHide(false);
  };

  const onClose1 = () => {
    setModalHide1(false);
  };

  const onClose2 = () => {
    setModalHide2(false);
  };

  const orgCreateValid = () => {
    let checked = true;

    if (orgName === "") {
      checked = false;
      setOrgNameErrMsg1("enter orgname");
    } else {
      setOrgNameErrMsg1("");
    }
    if (orgAddress === "") {
      checked = false;
      setorgAddressErrMsg1("enter orgaddress");
    } else {
      setorgAddressErrMsg1("");
    }
    if (orgType === "") {
      checked = false;
      setorgTypeErrMsg1("enter orgtype");
    } else {
      setorgTypeErrMsg1("");
    }
    // if (orgRegDate === "") {
    //     checked = false;
    //     setorgRegDateErrMsg("enter orgregdate")
    // } else {
    //     setorgRegDateErrMsg("")
    // }
    return checked;
  };

  const orgValid = () => {
    let checked = true;

    if (updateOrganization1?.org_nm === "") {
      checked = false;
      setOrgNameErrMsg1("enter orgname");
    } else {
      setOrgNameErrMsg1("");
    }
    if (updateOrganization1?.org_add === "") {
      checked = false;
      setorgAddressErrMsg1("enter orgaddress");
    } else {
      setorgAddressErrMsg1("");
    }
    if (updateOrganization1?.org_type === "") {
      checked = false;
      setorgTypeErrMsg1("enter orgtype");
    } else {
      setorgTypeErrMsg1("");
    }
    // if (orgRegDate === "") {
    //     checked = false;
    //     setorgRegDateErrMsg("enter orgregdate")
    // } else {
    //     setorgRegDateErrMsg("")
    // }
    return checked;
  };

  const createOrg = async () => {
    const data = {
      org_nm: orgName,
      org_add: orgAddress,
      org_type: orgType,
      org_regdate: currentDate,
    };
    try {
      const res = await createOrganization(data);
      if (res?.status === 200) {
        toast.success("new organization created");
        getAllOrg();
        setModalHide(false);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        toast.error(err?.response?.data?.data);
      }
    }
  };

  const onOrgSubmit = () => {
    if (orgCreateValid()) {
      createOrg();

      setOrgName("");
      setOrgAddress("");
      setOrgType("");
      setOrgRegDate("");
    }
    setModalHide(true);
  };

  useEffect(() => {
    if (modalHide === false) {
      setOrgName("");
      setOrgAddress("");
      setOrgType("");
      setOrgRegDate("");
    }
  }, [modalHide]);

  const handleAddStudentClick = () => {
    setModalHide(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleOrgEditClick = (orgData) => {
    setEditOrgId(orgData.org_id);
    setModalHide1(true);
    setUpdateOrganization1(orgData);
  };

  const updateInputChange = (e) => {
    setUpdateOrganization1({
      ...updateOrganization1,
      [e.target.name]: e.target.value,
    });
  };

  const updateOrg = async () => {
    const data = { ...updateOrganization1 };
    try {
      const res = await updateOrganization(EditOrgId, data);
      if (res?.status === 200) {
        getAllOrg();
        toast.success("updated successfully");
        setModalHide1(false);
      }
    } catch (error) {
      console.log("ss", error);
    }
  };

  const onEdit = async () => {
    if (orgValid()) {
      updateOrg();
      setModalHide1(false);
    }
  };

  const userValid = () => {
    let checked = true;

    if (userName === "") {
      checked = false;
      setUNameErrMsg("enter userName");
      console.log("checked1", checked);
    } else {
      setUNameErrMsg("");
    }
    if (unm === "") {
      checked = false;
      setUnmErrMsg("enter userName");
    } else {
      setUnmErrMsg("");
    }
    if (password === "") {
      checked = false;
      setPswErrMsg("enter password");
    } else {
      setPswErrMsg("");
    }
    if (address === "") {
      checked = false;
      setAdderrMsg("enter address");
    } else {
      setAdderrMsg("");
    }
    if (mobNumber === "") {
      checked = false;
      setMobErrMsg("enter mobile number");
    } else {
      setMobErrMsg("");
    }
    return checked;
  };

  const createUser = async () => {
    const data = {
      utype_id: "ut_FtmOsyj86",
      name: userName,
      Unm: unm,
      password: password,
      Uaddress: address,
      umob: mobNumber,
      org_id: adminOrgId,
      uorg_idcard: "",
    };
    try {
      const res = await newUserCreate(data);
      if (res?.status === 200) {
        getAllOrg();
        toast.success("new admin created");
        setModalHide2(false);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        toast.error(err?.response?.data?.data);
      }
    }
  };

  const onUserSubmit = () => {
    if (userValid()) {
      createUser();

      setUserName("");
      setUnm("");
      setPassword("");
      setAdderss("");
      setMobNumber("");
    }
    setModalHide2(true);
  };

  useEffect(() => {
    if (modalHide2 === false) {
      setUserName("");
      setUnm("");
      setPassword("");
      setAdderss("");
      setMobNumber("");
    }
  }, [modalHide2]);

  const handleAddAdminClick = (id) => {
    setModalHide2(true);
    setAdminOrgId(id);
  };

  return (
    <div className="w-[100vw-60px] h-[100vh] bg-background overflow-y-scroll">
      <div className="z-40 w-max h-max flex  items-center p-2 gap-1 absolute top-0 left-0">
        <a href="http://medtrain.ai" target="_medtrainai">
          <div className="manikin-cont w-[100px] h-[20px]">
            {localStorage.theme === "dark" ? (
              <img src={logo2} alt="logo" className="" />
            ) : (
              <img src={logo} alt="logo" className="" />
            )}
          </div>
        </a>
      </div>

      <div className="px-4 pt-4">
        <div className="flex justify-end gap-3 items-center">
          <div className=" text-headingTxt2 font-extrabold text-2xl">
            Create New Organization
          </div>
          <div
            className="border-[0.1px]  text-textColor flex justify-center items-center"
            onClick={handleAddStudentClick}
          >
            {/* <AddIcon/> */}
            <IoMdAdd />
          </div>
        </div>
        <div className=" h-[100%] grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-20 py-4  place-items-center overflow-y-scroll">
          {orgData?.map((org, i) => {
            return (
              <div className="flex bg-background flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[23rem] p-2 ">
                <div className="w-[100%] h-max flex flex-col gap-2">
                  <div className="pb-2">
                    <div className=" text-headingTxt2 font-semibold text-base">
                      {org?.org_nm}
                    </div>
                  </div>
                  <div>
                    <div className=" text-textColor font-extrabold text-sm">
                      Org ID:
                    </div>
                    <div className=" text-textColor font-normal">
                      {org?.org_id}
                    </div>
                  </div>
                  <div>
                    <div className=" text-textColor font-extrabold text-sm">
                      Org Admin:
                    </div>
                    <div className=" text-textColor font-normal">
                      {org?.allocation?.map((admin, i) => {
                        return <div key={i}>{admin?.organizersuser?.Unm}</div>;
                      })}
                    </div>
                  </div>
                  <div>
                    <div className=" text-textColor font-extrabold text-sm">
                      Org Type:
                    </div>
                    <div className=" text-textColor font-normal">
                      {org?.org_type}
                    </div>
                  </div>
                  <div>
                    <div className=" text-textColor font-extrabold text-sm">
                      Address
                    </div>
                    <div className=" text-textColor font-normal">
                      {org?.org_add}
                    </div>
                  </div>
                </div>
                <div className="w-[100%] flex justify-end items-center">
                  <div className="w-[100%] h-max flex justify-end items-center gap-1">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleAddAdminClick(org?.org_id)}
                    >
                      <TooltipUi
                        name="Add Org Admin"
                        icons={<IoMdAdd className=" text-textColor" />}
                        width="max"
                        placement="bottom"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(`/organization/${org?.org_id}/manikin`);
                      }}
                    >
                      <TooltipUi
                        name="Details"
                        icons={<GiInfo className=" text-textColor" />}
                        width="max"
                        placement="bottom"
                      />
                    </div>

                    <div
                      className="cursor-pointer"
                      onClick={() => handleOrgEditClick(org)}
                    >
                      <TooltipUi
                        name="Edit Organization"
                        icons={
                          <IoSettingsOutline className=" text-textColor" />
                        }
                        width="max"
                        placement="bottom"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <AssignTestModal
        heading={"Create Student"}
        handleClose={onClose}
        open={modalHide}
      >
        <div className="p-4 font-extrabold text-headingTxt2 text-xl">
          Create New Organization
        </div>
        <form className="flex w-[500px] h-max flex-col gap-4 px-4 py-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgName"
                  className="text-textColor font-medium text-lg"
                >
                  Org Name
                </label>
                <input
                  type="text"
                  id="orgName"
                  placeholder="Enter Org Name"
                  className="text-textColor outline-none placeholder-textColor bg-modalInputBg transition duration-300 border-b-2 focus:border-inputBorderColor font-medium px-2 py-2"
                  onChange={(e) => setOrgName(e.target.value)}
                  value={orgName}
                  name="orgName"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {orgName === "" ? orgNameErrMsg1 : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgType"
                  className="text-textColor font-medium text-lg"
                >
                  Org Type
                </label>
                <input
                  type="text"
                  id=""
                  placeholder="Enter Org Type"
                  className=" text-textColor outline-none placeholder-textColor transition duration-300 border-b-2 bg-modalInputBg focus:border-inputBorderColor font-medium px-2 py-2"
                  onChange={(e) => setOrgType(e.target.value)}
                  value={orgType}
                  name="orgType"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {orgType === "" ? orgTypeErrMsg1 : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgAddress"
                  className="text-textColor font-medium text-lg"
                >
                  Org Address
                </label>
                <textarea
                  id=""
                  placeholder="Enter Org Address"
                  className=" text-textColor outline-none placeholder-textColor transition duration-300 border-b-2 focus:border-inputBorderColor bg-modalInputBg font-medium p-2"
                  onChange={(e) => setOrgAddress(e.target.value)}
                  value={orgAddress}
                  name="orgAddress"
                ></textarea>
              </div>
              <div className="text-[0.8rem] text-red-500">
                {orgAddress === "" ? orgAddressErrMsg1 : ""}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgRegDate"
                  className="text-textColor font-medium text-lg"
                >
                  Org Register Date
                </label>
                <input
                  type="text"
                  id="orgRegDate"
                  className=" bg-modalInputBg text-textColor placeholder-textColor outline-none transition duration-300 border-b-2 focus:border-inputBorderColor p-2"
                  // onChange={(e) => setOrgRegDate(e.target.value)}
                  value={currentDate}
                  name="orgRegDate"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {orgRegDate === "" ? orgRegDateErrMsg1 : ""}
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 pt-4">
            <div
              className="w-max py-[0.7rem] px-[0.7rem] text-textColor transition-all duration-200 hover:cursor-pointer font-medium"
              onClick={() => setModalHide(false)}
            >
              Cancel
            </div>
            <div
              className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
              onClick={onOrgSubmit}
            >
              Create
            </div>
          </div>
        </form>
      </AssignTestModal>

      <AssignTestModal
        heading={"Edit Organization"}
        handleClose={onClose1}
        open={modalHide1}
      >
        <div className="p-4 font-extrabold text-xl text-headingTxt2">
          Edit Organization
        </div>
        <form className="flex w-[500px] h-max flex-col gap-4 px-4 py-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgName"
                  className="text-textColor font-medium text-lg"
                >
                  Org Name
                </label>
                <input
                  type="text"
                  id="orgName"
                  placeholder="Enter Org Name"
                  className=" text-textColor bg-modalInputBg outline-none transition duration-300 border-b-2 focus:border-inputBorderColor font-medium px-2 py-2"
                  onChange={updateInputChange}
                  value={updateOrganization1?.org_nm}
                  name="org_nm"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {updateOrganization1?.org_nm === "" ? orgNameErrMsg1 : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgType"
                  className="text-textColor font-medium text-lg"
                >
                  Org Type
                </label>
                <input
                  type="text"
                  id=""
                  placeholder="Enter Org Type"
                  className=" text-textColor bg-modalInputBg outline-none transition duration-300 border-b-2 focus:border-inputBorderColor font-medium px-2 py-2"
                  onChange={updateInputChange}
                  value={updateOrganization1?.org_type}
                  name="org_type"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {updateOrganization1?.org_type === "" ? orgTypeErrMsg1 : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgAddress"
                  className="text-textColor font-medium text-lg"
                >
                  Org Address
                </label>
                <textarea
                  id=""
                  placeholder="Enter Org Address"
                  className=" text-textColor bg-modalInputBg outline-none transition duration-300 border-b-2 focus:border-inputBorderColor font-medium p-2 py-2"
                  onChange={updateInputChange}
                  value={updateOrganization1?.org_add}
                  name="org_add"
                ></textarea>
              </div>
              <div className="text-[0.8rem] text-red-500">
                {updateOrganization1?.org_add === "" ? orgAddressErrMsg1 : ""}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="orgRegDate"
                  className="text-textColor font-medium text-lg"
                >
                  Org Register Date
                </label>
                <input
                  type="text"
                  id="orgRegDate"
                  className="outline-none transition duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg  disabled:cursor-not-allowed p-2"
                  onChange={updateInputChange}
                  value={updateOrganization1?.org_regdate}
                  name="orgRegDate"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {updateOrganization1?.org_regdate === ""
                  ? orgRegDateErrMsg1
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 pt-4">
            <div
              className="w-max py-[0.7rem] px-[0.7rem] transition-all duration-200 text-textColor hover:cursor-pointer font-medium"
              onClick={() => setModalHide1(false)}
            >
              Cancel
            </div>
            <div
              className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
              onClick={onEdit}
            >
              Save
            </div>
          </div>
        </form>
      </AssignTestModal>

      <AssignTestModal
        heading={"Create Organization Admin"}
        handleClose={onClose2}
        open={modalHide2}
      >
        <div className="p-4 font-extrabold text-headingTxt2 text-xl">
          Create Organization Admin
        </div>
        <form className="flex w-[500px] h-max flex-col rounded-md gap-4 px-4 py-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="userName"
                  className="text-textColor font-medium text-lg"
                >
                  Name
                </label>
                <input
                  type="text"
                  id=""
                  placeholder="Enter Name"
                  className=" bg-modalInputBg placeholder-textColor outline-none transition duration-300 border-b-2 focus:border-inputBorderColor font-medium text-textColor px-2 py-2"
                  onChange={(e) => setUserName(e.target.value)}
                  value={userName}
                  name="userName"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {userName === "" ? uNameErrMsg : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="unm"
                  className="text-textColor font-medium text-lg"
                >
                  UserName
                </label>
                <input
                  type="text"
                  id="unm"
                  placeholder="Enter UserName"
                  className=" text-textColor bg-modalInputBg placeholder-textColor outline-none transition duration-300 border-b-2 focus:border-inputBorderColor font-medium px-2 py-2"
                  onChange={(e) => setUnm(e.target.value)}
                  value={unm}
                  name="unm"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {unm === "" ? unmErrMsg : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="password"
                  className="text-textColor font-medium text-lg"
                >
                  Password
                </label>
                <input
                  type="text"
                  id=""
                  placeholder="Enter Password"
                  className=" text-textColor bg-modalInputBg placeholder-textColor outline-none transition duration-300 border-b-2 focus:border-inputBorderColor font-medium px-2 py-2"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  name="password"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {password === "" ? pswErrMsg : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="address"
                  className="text-textColor font-medium text-lg"
                >
                  Address
                </label>
                <textarea
                  id=""
                  placeholder="Enter Your Address"
                  className=" outline-none transition duration-300 border-b-2 placeholder-textColor focus:border-inputBorderColor bg-modalInputBg text-textColor font-medium p-2"
                  onChange={(e) => setAdderss(e.target.value)}
                  value={address}
                  name="address"
                ></textarea>
              </div>
              <div className="text-[0.8rem] text-red-500">
                {address === "" ? addErrMsg : ""}
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="flex flex-col justify-start gap-2">
                <label
                  htmlFor="mobNumber"
                  className="text-textColor font-medium text-lg"
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  id=""
                  placeholder="Enter Mobile number"
                  className=" outline-none transition duration-300 border-b-2 placeholder-textColor focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                  onChange={(e) => setMobNumber(e.target.value)}
                  value={mobNumber}
                  name="mobNumber"
                />
              </div>
              <div className="text-[0.8rem] text-red-500">
                {mobNumber === "" ? mobErrMsg : ""}
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 pt-4">
            <div
              className="w-max py-[0.7rem] px-[0.7rem]  transition-all duration-200 text-textColor hover:cursor-pointer font-medium"
              onClick={() => setModalHide2(false)}
            >
              Cancel
            </div>
            <div
              className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
              onClick={onUserSubmit}
            >
              Create
            </div>
          </div>
        </form>
      </AssignTestModal>
    </div>
  );
};

export default Organization;
