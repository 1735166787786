import React, { memo, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./graph-style.css";
import { GRAPH_TIME } from "../../../components/common/GraphInfo";
const Graph = memo(
  ({
    incrementalX,
    yCoordinates,
    color_code,
    width,
    height,
    max_points,
    stroke,
    domain,
    smooth,
    pathId,
    gap,
    upperLowerRange,
    scale,
  }) => {
    let getLengthOfXValues = incrementalX.length;
    let getLengthOfYValues = yCoordinates.length;
    // define indices for x and y coordinate values to be drawn
    let xIndex = 0;
    let yIndex = 0;
    //define indices to track increments of x values
    let xIncrement = 0;

    let svgRef = useRef(null);

    useEffect(() => {
      console.log(max_points, " max points...")
      //this is a copy of dataset to hold the value of dataset in setInterval context
      let total_points_in_cycle =
        max_points - (max_points % getLengthOfYValues) + 1;
      let data = [];

      let count = 0;

      let lineToDraw;
      let valueline;
      let svg;
      let clipPath1;
      let clipPath2;
      let path1;
      let path2;
      let timeToDraw = parseInt(GRAPH_TIME * scale);
      // Define scales
      let x = d3
        .scaleLinear()
        // .domain([0, d3.max(currentDataset.current, (d) => d.x)])
        .domain([0, domain])
        .range([0, width]);
      let y = d3
        .scaleLinear()
        .domain([d3.min(yCoordinates), d3.max(yCoordinates)])
        .range([height, 0]);
      // Define axes
      // const xAxis = d3.axisBottom(x).ticks(5);
      // const yAxis = d3.axisLeft(y).ticks(5);

      // Define line generator
      valueline = d3
        .line()
        .x((d) => x(d.x))
        .y((d) => y(d.y))
        .curve(smooth ? d3.curveBasis : d3.curveLinear);
      //remove previous svg div
      d3.select(svgRef.current).selectAll("*").remove();

      // Create SVG container
      svg = d3
        .select(svgRef.current)
        .append("svg")
        // .attr("width", "100%")
        // .attr("max-height", "100%")
        .attr("preserveAspectRatio", "none")
        // .attr("viewBox", `0 0 600 408`)
        .classed("graph-content", true)
        .append("g");
      // .attr("transform", `translate(${margin.left},${margin.top})`);

      // generate data points to draw path
      generateXYData();
      // Add paths with clip paths
      const path = svg
        .append("path")
        .attr("id", pathId)
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", color_code)
        .attr("stroke-width", stroke)
        .attr("d", valueline);

      const totalLength = path.node().getTotalLength();

      // Animate the path
      path
        .attr("stroke-dasharray", `${totalLength} ${totalLength}`)
        .attr("stroke-dashoffset", totalLength)
        .transition()
        .duration(timeToDraw) //total time to draw all points
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0);

      svg
        .append("rect")
        .attr("width", gap)
        .attr("height", upperLowerRange)
        .attr("transform", `translate(-${gap / 2}, -${upperLowerRange / 2})`)
        .attr("fill", "#181717")
        .append("animateMotion")
        .attr("dur", `${timeToDraw / 1000}s`)
        .attr("repeatCount", "indefinite")
        .append("mpath")
        .attr("href", `#${pathId}`); // Reference the path with the ID

      function generateXYData() {
        // Getting x and y coordinates by calculating the indices from xIncrement and yCoordinate
        while (data.length < total_points_in_cycle) {
          const point = {
            x: xIndex,
            y: yCoordinates[yIndex],
          };

          data.push(point);
          // the following increment order is critical

          // incrementing xIndex
          xIndex += incrementalX[xIncrement];
          // getting y coodinates in a circular manner
          yIndex = ++yIndex % getLengthOfYValues;
          // incrementing x coodinates in a circular manner
          xIncrement = ++xIncrement % getLengthOfXValues;
        }
      }
      // Function to update data and toggle paths
    }, [max_points]);

    return <div ref={svgRef} className="graph-container"></div>;
  }
);

export default Graph;
