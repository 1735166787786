import React, { useState, useEffect } from "react";
import "./sessionContainer.css";
import { IoMdPause } from "react-icons/io";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import EasySpeech from "easy-speech";
import { motion, AnimatePresence } from "framer-motion";
import scenario from "./data/scenario";

const AssesmentPanel = ({ children, open, onClose, record }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [btnHide, setBtnHide] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  const textToSpeech = async (action) => {
    try {
      // Extract relevant data and convert it to a readable string
      const patientDetails = scenario[activeTab].scenario.patientDetails;
      const presentation = scenario[activeTab].scenario.presentation;
      const management = scenario[activeTab].scenario.management;

      const textToRead = `
      Scenario Title:${patientDetails.title}.
        Patient Name: ${patientDetails.name}.
        Age: ${patientDetails.age}.
        Gender: ${patientDetails.gender}.
        Weight: ${patientDetails.weight}.
        Known Allergies: ${patientDetails.medicalHistory.knownAllergies}.
        Chronic Conditions: ${patientDetails.medicalHistory.chronicConditions}.
        Chief Complaint: ${presentation.chiefComplaint}.
        Vital Signs: Heart Rate ${presentation.vitalSigns.heartRate}, 
        Respiratory Rate ${presentation.vitalSigns.respiratoryRate}, 
        Oxygen Saturation ${presentation.vitalSigns.oxygenSaturation}, 
        Blood Pressure ${presentation.vitalSigns.bloodPressure}.
        Symptoms: ${presentation.symptoms.join(", ")}.
        Initial Actions: ${management.initialActions.join(", ")}.
        Nebulized Medication: ${
          management.interventions.nebulizedMedications.medication
        },
        Dose: ${management.interventions.nebulizedMedications.dose}.
        Intubation Indication: ${
          management.interventions.intubation.indication
        }.
        Advanced Interventions Indication:${
          management.advancedInterventions.Anintubation.indication2
        }
        Advanced Interventions Procedure: ${
          management.advancedInterventions.Anintubation.Procedure
        }
        Monitor Oxygen Saturation: ${
          management.MonitoringAndFollowUp.MonitorOxygenSaturation
        }
        Monitor for Complications: ${
          management.MonitoringAndFollowUp.MonitorforComplications
        }
        If Obstruction is Relieved:${
          management.PostInterventionCare.IfObstructionisRelieved
        }
        Invasive Monitoring:${
          management.PostInterventionCare.InvasiveMonitoring
        }
        Stabilization: ${management.DischargeCriteria.Stabilization}
        Instructor’s Role:${management.InstructorRole}
        Conclusion:${management.Conclusion}
        ET Tube Size: ${management.interventions.intubation.ETTubeSize}.
        Description: ${presentation.vitalSigns.description}.
        Your objective is to manage the airway on this patient. All the best!
      `;

      setBtnHide(!btnHide);

      await EasySpeech.init();

      const voices = EasySpeech.voices();
      const voice = voices[2]; // You can adjust the index based on your preference

      const utterance = new SpeechSynthesisUtterance(textToRead.trim());
      utterance.voice = voice;
      utterance.onerror = (event) => {
        console.error("Speech synthesis error:", event);
      };

      utterance.onend = () => {
        console.log("Speech synthesis complete.");
        setBtnHide(false);
      };

      if (action === "play") {
        await EasySpeech.speak(utterance);
      } else {
        EasySpeech.cancel();
      }
    } catch (error) {
      console.error("An error occurred during speech synthesis:", error);
    }
  };

  useEffect(() => {
    if (open) {
      setIsPlaying(true);
      textToSpeech("play"); // Start text-to-speech when the modal opens.
    } else {
      textToSpeech("stop");
      // Stop text-to-speech when the modal closes.
    }

    // Cleanup function to stop text-to-speech when the component unmounts or modal closes.
    return () => {
      textToSpeech("stop");
    };
  }, [open, activeTab]); // Dependency array to react to changes in `open` or `activeTab`.

  // variants for animation using framer motion
  const popupVariants = {
    hidden: { opacity: 0, scale: 0.8, y: -50 },
    visible: { opacity: 0.9, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.8, y: 50 },
  };

  // array of scenarios below

  const tabs = [
    "Scenario 1",
    "Scenario 2",
    "Scenario 3",
    "Scenario 4",
    "Scenario 5",
    "Scenario 6",
    "Scenario 7",
    "Scenario 8",
  ];

  // array of scenarios above

  if (!open) return null;
  return (
    <div className="search-session-modal">
      <motion.div
        className="relative w-[80%] h-[90%] bg-backgroundModal text-opacity-100 opacity-95  p-4 "
        variants={popupVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {/* Tabs of scenarios mapped from the scenarios array */}
        <div className="w-full flex   tabs-scrollbar overflow-x-auto pt-2">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`flex justify-start hover:text-yellow-200 text-center  min-w-fit text-xl px-2 font-semibold  ${
                activeTab === index
                  ? "border-b-2 border-blue-500 text-yellow-400"
                  : "text-textColor"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* play and pause button for text to speech */}

        <div className="w-full p-2">
          {isPlaying ? (
            <button
              className="bg-red-600 hover:bg-red-700 text-center flex items-center px-3 py-1 text-white cursor-pointer "
              onClick={() => {
                textToSpeech("stop");
                setIsPlaying(false);
              }}
            >
              <IoMdPause size={30} className="" />
              Pause
            </button>
          ) : (
            <button
              className="bg-red-600 hover:bg-red-700 text-center flex items-center px-3 py-1 text-white cursor-pointer"
              onClick={() => {
                textToSpeech("play");
                setIsPlaying(true);
              }}
            >
              <HiMiniSpeakerWave size={30} className="" />
              Listen
            </button>
          )}
        </div>

        {/* scenario details below */}

        <div className="w-full text-textColor overflow-y-auto tabdetails-scrollbar text-opacity-100 text-center p-2 h-[78%]">
          {scenario[activeTab].scenario.patientDetails.title && (
            <div className="text-justify w-full">
              {" "}
              <p className="text-xl font-semibold">
                Title:{" "}
                <span className="font-normal text-textColor">
                  {scenario[activeTab].scenario.patientDetails.title}
                </span>
              </p>
            </div>
          )}
          <div className="w-full flex justify-between gap-x-2">
            {scenario[activeTab].scenario.patientDetails.name && (
              <p className="text-xl font-semibold">
                Patient Name:{" "}
                <span className="font-normal text-textColor">
                  {scenario[activeTab].scenario.patientDetails.name}
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.patientDetails.age && (
              <p className="text-xl font-semibold">
                Patient Age:{" "}
                <span className="font-normal text-textColor">
                  {scenario[activeTab].scenario.patientDetails.age}
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.patientDetails.gender && (
              <p className="text-xl font-semibold">
                Patient Gender:{" "}
                <span className="font-normal text-textColor">
                  {scenario[activeTab].scenario.patientDetails.gender}
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.patientDetails.weight && (
              <p className="text-xl font-semibold">
                Patient Weight:{" "}
                <span className="font-normal text-textColor">
                  {scenario[activeTab].scenario.patientDetails.weight}
                </span>
              </p>
            )}
          </div>
          <div className="w-full flex justify-between">
            {scenario[activeTab].scenario.patientDetails.medicalHistory
              .knownAllergies && (
              <p className="text-xl font-semibold">
                Known Allergies:{" "}
                <span className="font-normal text-textColor">
                  {
                    scenario[activeTab].scenario.patientDetails.medicalHistory
                      .knownAllergies
                  }
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.patientDetails.medicalHistory
              .chronicConditions && (
              <p className="text-xl font-semibold">
                Chronic Conditions:{" "}
                <span className="font-normal text-textColor">
                  {
                    scenario[activeTab].scenario.patientDetails.medicalHistory
                      .chronicConditions
                  }
                </span>
              </p>
            )}
          </div>
          {scenario[activeTab].scenario.presentation.chiefComplaint && (
            <div className="flex w-full flex-col text-justify">
              <h5 className="font-semibold text-xl">Complaint :</h5>
              <p className="text-lg">
                {scenario[activeTab].scenario.presentation.chiefComplaint}
              </p>
            </div>
          )}
          <div className="w-full text-xl flex gap-5 justify-start">
            {scenario[activeTab].scenario.presentation.vitalSigns.heartRate && (
              <p className="font-semibold text-xl">
                Heart Rate:{" "}
                <span className="font-normal text-text">
                  {
                    scenario[activeTab].scenario.presentation.vitalSigns
                      .heartRate
                  }
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.presentation.vitalSigns
              .respiratoryRate && (
              <p className="font-semibold text-xl">
                Respiratory Rate:{" "}
                <span className="font-normal text-textColor">
                  {
                    scenario[activeTab].scenario.presentation.vitalSigns
                      .respiratoryRate
                  }
                </span>
              </p>
            )}
          </div>
          <div className="w-full text-xl flex gap-5 justify-start">
            {scenario[activeTab].scenario.presentation.vitalSigns
              .oxygenSaturation && (
              <p className="font-semibold text-xl">
                Oxygen Saturation:{" "}
                <span className="text-textColor font-normal">
                  {
                    scenario[activeTab].scenario.presentation.vitalSigns
                      .oxygenSaturation
                  }
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.presentation.vitalSigns
              .bloodPressure && (
              <p className="font-semibold text-xl">
                Blood Pressure:{" "}
                <span className="text-textColor font-normal">
                  {
                    scenario[activeTab].scenario.presentation.vitalSigns
                      .bloodPressure
                  }
                </span>
              </p>
            )}
          </div>
          {scenario[activeTab].scenario.presentation.symptoms && (
            <div className="w-full">
              <p className="text-justify text-xl font-semibold">
                Symptomps:{" "}
                <span className="font-normal text-textColor">
                  {scenario[activeTab].scenario.presentation.symptoms.join(
                    ", "
                  )}{" "}
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.initialActions && (
            <div className="w-full">
              <p className="text-justify text-xl">
                <span className=" font-semibold">Initial Actions: </span>
                <span className="">
                  {scenario[activeTab].scenario.management.initialActions.join(
                    ", "
                  )}{" "}
                </span>
              </p>
            </div>
          )}
          <div className="w-full flex justify-start gap-5 text-lg">
            {scenario[activeTab].scenario.management.interventions
              .nebulizedMedications.medication && (
              <p className="font-semibold text-xl">
                Nebulized Medication:{" "}
                <span className="font-normal text-textColor">
                  {
                    scenario[activeTab].scenario.management.interventions
                      .nebulizedMedications.medication
                  }
                </span>
              </p>
            )}

            {scenario[activeTab].scenario.management.interventions
              .nebulizedMedications.dose && (
              <p className="font-semibold text-xl">
                Dose:{" "}
                <span className="font-normal text-textColor">
                  {
                    scenario[activeTab].scenario.management.interventions
                      .nebulizedMedications.dose
                  }
                </span>
              </p>
            )}
          </div>

          {scenario[activeTab].scenario.management.interventions.intubation
            .indication && (
            <div className="w-full">
              <p className="text-xl text-justify">
                <span className="font-semibold ">Indication: </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management.interventions
                      .intubation.indication
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.advancedInterventions
            .Anintubation.indication2 && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className=" font-semibold">
                  Advanced Interventions Indication:{" "}
                </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management
                      .advancedInterventions.Anintubation.indication2
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.advancedInterventions
            .Anintubation.Procedure && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className=" font-semibold">
                  Advanced Interventions Procedure:{" "}
                </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management
                      .advancedInterventions.Anintubation.Procedure
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.MonitoringAndFollowUp
            .MonitorOxygenSaturation && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className=" font-semibold">
                  Monitor Oxygen Saturation:{" "}
                </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management
                      .MonitoringAndFollowUp.MonitorOxygenSaturation
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.MonitoringAndFollowUp
            .MonitorforComplications && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className="  font-semibold">
                  Monitor for Complications:{" "}
                </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management
                      .MonitoringAndFollowUp.MonitorforComplications
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.PostInterventionCare
            .IfObstructionisRelieved && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className="font-semibold">
                  If Obstruction is Relieved:{" "}
                </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management.PostInterventionCare
                      .IfObstructionisRelieved
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.PostInterventionCare
            .InvasiveMonitoring && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className="font-semibold">Invasive Monitoring: </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management.PostInterventionCare
                      .InvasiveMonitoring
                  }
                </span>
              </p>
            </div>
          )}
          {scenario[activeTab].scenario.management.DischargeCriteria
            .Stabilization && (
            <div className="e-full">
              <p className="text-justify text-xl">
                <span className=" font-semibold">Stabilization: </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.management.DischargeCriteria
                      .Stabilization
                  }
                </span>
              </p>
            </div>
          )}
          <div className="w-full">
            {scenario[activeTab].scenario.management.InstructorRole && (
              <ol className="text-justify text-xl">
                <span className="  font-semibold">Instructor’s Role: </span>
                <li className="text-lg text-justify">
                  1.{" "}
                  {
                    scenario[activeTab].scenario.management.InstructorRole
                      .roleOne
                  }
                </li>
                <li className="text-lg text-justify">
                  2.{" "}
                  {
                    scenario[activeTab].scenario.management.InstructorRole
                      .roleTwo
                  }
                </li>
                <li className="text-lg text-justify">
                  3.{" "}
                  {
                    scenario[activeTab].scenario.management.InstructorRole
                      .roleThree
                  }
                </li>
                <li className="text-lg text-justify">
                  4.{" "}
                  {
                    scenario[activeTab].scenario.management.InstructorRole
                      .roleFour
                  }
                </li>
              </ol>
            )}
            {scenario[activeTab].scenario.management.Conclusion && (
              <div className="e-full">
                <p className="text-justify text-xl">
                  <span className="  font-semibold">Conclusion: </span>
                  <span className="text-lg">
                    {scenario[activeTab].scenario.management.Conclusion}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="w-full">
            {scenario[activeTab].scenario.management.interventions.intubation
              .ETTubeSize && (
              <p className="text-xl font-semibold text-justify">
                ET Tube Size:{" "}
                <span className=" text-textColor font-normal">
                  {
                    scenario[activeTab].scenario.management.interventions
                      .intubation.ETTubeSize
                  }
                </span>
              </p>
            )}
            {scenario[activeTab].scenario.presentation.vitalSigns
              .description && (
              <p className="text-xl text-justify">
                <span className="font-semibold">Description: </span>
                <span className="text-lg">
                  {
                    scenario[activeTab].scenario.presentation.vitalSigns
                      .description
                  }
                </span>
              </p>
            )}
            <p className="text-xl text-justify">
              Your objective is to manage the airway on this patient. All the
              best!
            </p>
          </div>
        </div>
        {/* scenario details above */}
        <div className="w-full flex justify-end gap-x-1">
          {" "}
          <button
            size={30}
            className="bg-gray-600 bg-opacity-100 text-opacity-100 px-4  py-2 text-center text-white hover:bg-gray-700 cursor-pointer"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className={`px-4 py-2 text-center text-opacity-100 ${
              scenario[activeTab].scenario.patientDetails.active
                ? "bg-red-600 text-white hover:bg-red-700 cursor-pointer"
                : "bg-red-400 text-gray-200 cursor-not-allowed"
            }`}
            disabled={!scenario[activeTab].scenario.patientDetails.active}
            onClick={record}
          >
            Start Recording
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default AssesmentPanel;
