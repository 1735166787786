import { forwardRef, useEffect, useState } from "react";
import { useDarkMode } from "../hooks/useDarkMode";

const ManikinSVG = forwardRef((props, ref) => {
  const theme = useDarkMode((state) => state.theme);
  const [fillErrMsg, setFillErrMsg] = useState(
    theme === "dark" ? "#181717" : "#ffffff"
  );
  useEffect(() => {
  }, [theme, fillErrMsg]);
  useEffect(() => {
    const errTextBgElem = document.querySelector("#err_bg");
    errTextBgElem.style.fill = theme === "dark" ? "#181717" : "#ffffff";
  }, [theme]);
  return (
    <svg
      id="manikin-container"
      xmlns="http://www.w3.org/2000/svg"
      width="max-content"
      height="100%"
      viewBox="0 0 1200 820"
      fill="none"
      ref={ref}
    >
      <g id="manikin">
        <rect
          id="bg_up"
          y="-6.10352e-05"
          width="1200"
          height="564"
          fill="var('--bg-secondary)"
          color="red"
        />
        <rect
          id="bg_bottom"
          y="570"
          width="1200"
          height="336"
          fill="var('--bg-secondary)"
        />
        <g xmlns="http://www.w3.org/2000/svg" id="Lary">
          <path
            id="Larry Connector"
            d="M441 214.5C435 217.7 425.5 219.5 421.5 220L426.5 243C434.9 240.6 444 239 447.5 238.5L441 214.5Z"
            fill="#D4D4D4"
            stroke="#434343"
            strokeWidth="4"
          />
          <path
            id="Lary Pipe"
            d="M346.5 227.5C364.9 227.5 396.167 217.833 409.5 213C413.5 209.8 418.833 211.667 421 213L429 245C429.8 249.8 422 252 418 252.5C410.8 253.3 388.667 260.167 378.5 263.5C380.9 323.9 407.5 380.5 426 398.5C433.2 410.9 456.333 411 467 409.5C480.2 420.3 462.833 423.333 452.5 423.5C389.7 429.5 355.667 335.667 346.5 288C333.7 243.2 332.833 232 334 232C338.4 228.8 344.167 227.667 346.5 227.5Z"
            fill="#777777"
            stroke="#434343"
            strokeWidth="4"
          />
          <g id="Vector">
            <path
              d="M435.031 206.019C434.484 203.879 435.78 201.723 437.928 201.203L609.026 159.782C611.173 159.262 613.357 160.576 613.905 162.716L624.416 203.826C624.963 205.967 623.666 208.123 621.519 208.643L450.421 250.064C448.274 250.584 446.089 249.27 445.542 247.13L435.031 206.019Z"
              fill="#D9D9D9"
            />
            <path
              d="M572.445 171.72C575.913 176.507 583.079 192.07 583.996 216.031M450.421 250.064L621.519 208.643C623.666 208.123 624.963 205.967 624.416 203.826L613.905 162.716C613.357 160.576 611.173 159.262 609.026 159.782L437.928 201.203C435.78 201.723 434.484 203.879 435.031 206.019L445.542 247.13C446.089 249.27 448.274 250.584 450.421 250.064Z"
              stroke="#434343"
              strokeWidth="4"
            />
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg" id="Tube">
          <mask id="path-7-inside-1_11_33" fill="white">
            <path d="M 380.905 63.4906 C 377.283 66.0816 376.267 68.1158 376.211 68.809 L 377.297 81.4524 C 377.646 82.3174 377.992 84.0695 376.579 84.1577 C 375.166 84.246 373.058 84.5747 372.18 84.7281 C 371.604 84.9612 370.528 85.7181 370.837 86.8812 C 371.146 88.0444 371.677 92.4453 371.904 94.5004 C 371.99 95.8746 372.861 98.4609 375.658 97.8132 C 378.456 97.1655 380.149 97.1386 380.646 97.2061 C 381.603 96.7522 383.443 96.5584 383.148 99.4147 C 382.854 102.271 383.038 103.954 383.167 104.439 C 382.197 104.696 380.178 105.808 379.854 108.193 C 379.53 110.578 380.369 116.438 380.829 119.07 C 381.111 120.432 383.723 124.874 384.38 123.873 C 385.038 122.873 391.503 152.165 385.611 195.629 C 384.279 204.285 378.811 227.802 367.591 252.625 C 359.008 271.39 345.254 315.27 358.901 340.668 C 400 424 452.448 403.406 439 403 C 373.115 350.54 368.3 320.768 370.773 308.296 C 369.537 280.941 398.381 229.713 412.958 207.519 C 432.747 176.013 466.049 145.673 480.226 134.441 C 491.563 134.206 505.244 124.798 510.667 120.124 L 514.679 118.1 C 514.973 115.244 518.605 114.701 520.384 114.787 C 524.826 113.328 534.154 109.02 535.936 103.47 C 537.717 97.9193 534.114 94.8138 532.09 93.9549 C 525.777 92.2799 517.201 96.9541 514.128 99.8065 C 511.669 102.088 509.827 101.947 509.214 101.591 C 478.177 112.044 472.749 120.423 473.914 123.306 C 445.513 141.871 413.615 181.76 401.217 199.384 C 406.385 187.811 406.225 140.4 404.514 124.389 C 406.752 122.358 407.999 120.23 408.342 119.421 C 408.171 116.672 407.665 110.476 407.018 107.679 C 406.37 104.882 403.619 103.753 402.325 103.538 L 401.736 94.1152 L 406.448 93.8209 L 407.276 92.8823 L 409.484 90.3795 L 409.926 87.9871 C 409.994 87.4902 409.926 86.0952 409.117 84.4903 C 408.307 82.8855 406.068 81.4291 405.05 80.9014 C 403.695 81.932 402.449 80.2756 401.995 79.3186 C 401.958 75.5765 401.848 67.5034 401.701 65.1478 C 401.554 62.7921 399.505 61.5405 398.499 61.2092 C 394.143 60.8901 384.527 60.8995 380.905 63.4906 Z" />
          </mask>
          <path
            d="M 380.905 63.4906 C 377.283 66.0816 376.267 68.1158 376.211 68.809 L 377.297 81.4524 C 377.646 82.3174 377.992 84.0695 376.579 84.1577 C 375.166 84.246 373.058 84.5747 372.18 84.7281 C 371.604 84.9612 370.528 85.7181 370.837 86.8812 C 371.146 88.0444 371.677 92.4453 371.904 94.5004 C 371.99 95.8746 372.861 98.4609 375.658 97.8132 C 378.456 97.1655 380.149 97.1386 380.646 97.2061 C 381.603 96.7522 383.443 96.5584 383.148 99.4147 C 382.854 102.271 383.038 103.954 383.167 104.439 C 382.197 104.696 380.178 105.808 379.854 108.193 C 379.53 110.578 380.369 116.438 380.829 119.07 C 381.111 120.432 383.723 124.874 384.38 123.873 C 385.038 122.873 391.503 152.165 385.611 195.629 C 384.279 204.285 378.811 227.802 367.591 252.625 C 359.008 271.39 345.254 315.27 358.901 340.668 C 400 424 452.448 403.406 439 403 C 373.115 350.54 368.3 320.768 370.773 308.296 C 369.537 280.941 398.381 229.713 412.958 207.519 C 432.747 176.013 466.049 145.673 480.226 134.441 C 491.563 134.206 505.244 124.798 510.667 120.124 L 514.679 118.1 C 514.973 115.244 518.605 114.701 520.384 114.787 C 524.826 113.328 534.154 109.02 535.936 103.47 C 537.717 97.9193 534.114 94.8138 532.09 93.9549 C 525.777 92.2799 517.201 96.9541 514.128 99.8065 C 511.669 102.088 509.827 101.947 509.214 101.591 C 478.177 112.044 472.749 120.423 473.914 123.306 C 445.513 141.871 413.615 181.76 401.217 199.384 C 406.385 187.811 406.225 140.4 404.514 124.389 C 406.752 122.358 407.999 120.23 408.342 119.421 C 408.171 116.672 407.665 110.476 407.018 107.679 C 406.37 104.882 403.619 103.753 402.325 103.538 L 401.736 94.1152 L 406.448 93.8209 L 407.276 92.8823 L 409.484 90.3795 L 409.926 87.9871 C 409.994 87.4902 409.926 86.0952 409.117 84.4903 C 408.307 82.8855 406.068 81.4291 405.05 80.9014 C 403.695 81.932 402.449 80.2756 401.995 79.3186 C 401.958 75.5765 401.848 67.5034 401.701 65.1478 C 401.554 62.7921 399.505 61.5405 398.499 61.2092 C 394.143 60.8901 384.527 60.8995 380.905 63.4906 Z"
            fill="white"
            stroke="#68C3D6"
            strokeWidth="6"
            mask="url(#path-7-inside-1_11_33)"
          />
        </g>
        <path
          id="manikinBody"
          fill="var(--svgManikinBodyColor)"
          stroke="#5C4242"
          strokeWidth="4"
        />
        <g xmlns="http://www.w3.org/2000/svg" id="Lungs">
          <g id="left_lung">
            <path
              id="Lungs_2_Main"
              d="M698.5 368C678.1 394 679.333 404.5 682.5 406.5C762.5 474.5 917.5 482.833 985 478.5C995.8 476.5 999.5 467 1000 462.5C994 453.7 994.833 443.167 996 439C1024.8 379.4 1042.67 322.833 1048 302C1058 271.6 997.833 266.667 966.5 268C803.7 286.8 720 342.5 698.5 368Z"
              fill="var(--svgLeftLungColor)"
              stroke="#C1272D"
              strokeWidth="4"
            />
            {/* <path
              id="Lungs_2_Markings"
              d="M896.515 312.662C898.974 308.562 908.834 308.971 911.115 304.409M910.48 317.74C914.175 317.546 917.704 315.458 921.272 314.566"
              stroke="#D64F67"
              strokeWidth="4"
              strokeLinecap="round"
            /> */}
            {/* <path
              id="Lungs_2_shadow"
              d="M987.03 341.5C996.63 304.3 1024.03 286.333 1036.53 282C1053.55 290.011 1043.3 314.942 1037 327C1035.7 329.488 1033.11 332.205 1032.03 334L1023.03 356C1018.63 367.6 1006.53 367.167 1001.03 365.5C989.03 365.5 986.696 349.5 987.03 341.5Z"
              fill="#D64F67"
            /> */}
          </g>
        </g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          id="funnel"
          fill="#E87272"
          stroke="#C1272D"
          strokeWidth="4"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          id="esophagus"
          d="M369.186 361C386.167 406.728 422.698 464.006 524.5 457.5L540.5 453.5"
          stroke="#FFB27A"
          strokeWidth="2"
          strokeDasharray="4 4"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          id="trachea"
          d="M369.191 361C380.833 392.217 398.799 420.782 425 431.5C469 449.5 489.5 434 528 425C566.5 416 646 404.5 750.5 437L778.5 445"
          stroke="#FFB27A"
          strokeWidth="2"
          strokeDasharray="4 4"
        />
        <g xmlns="http://www.w3.org/2000/svg" id="arrow">
          <path
            d="M370.238 324.905L368.253 360.579L340.905 337.587"
            stroke="#F0D000"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="baloon"
            cx="369.5"
            cy="365.5"
            r="11.5"
            fill="#F0D000"
            fillOpacity="0.5"
            stroke="black"
            strokeWidth="2"
          />
        </g>
        {/* <g id="Degree Viewer" fill="#D64F67">
          <path
            id="deg_line_y"
            d="M53 29V170"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            id="deg_line_x"
            d="M53 170L194 170"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <text
            x="160"
            y="150"
            id="deg_text"
            style={{ color: "#d64f67", fontWeight: 700, fontSize: "30px" }}
          >
            0
          </text>
          <text
            x="200"
            y="140"
            id="deg_text"
            style={{ color: "#d64f67", fontWeight: 700, fontSize: "20px" }}
          >
            °
          </text>
        </g> */}
        <g id="Error_Message">
          <rect id="err_bg" x="425" y="710" width="600" height="50" />
          <text
            x="475"
            y="750"
            id="err_text"
            style={{ color: "black", fontWeight: 700, fontSize: "30px" }}
          ></text>
        </g>
        <g id="beacon">
          <circle id="beeper" cx="370" cy="360" r="18" fill="yellow"></circle>
          <circle id="point" cx="370" cy="360" r="9" fill="yellow"></circle>
        </g>
      </g>

      <g xmlns="http://www.w3.org/2000/svg" id="Lungs">
        <g id="right_lung">
          <path
            id="Lungs_1_Main"
            d="M1003 550.5C834.2 541.7 770 490.833 759 466.5C742.6 456.5 760.5 433.333 771.5 423C899.5 332.2 1003.5 324.167 1039.5 331.5C1075.5 332.3 1104.5 410.833 1114.5 450C1114.5 459.2 1123.5 488.833 1128 502.5C1122.8 550.1 1042.5 554.333 1003 550.5Z"
            fill="#f9f9fa82"
            stroke="#C1272D"
            strokeWidth="4"
          />
          {/* <path
              id="Lungs_1 Lines"
              d="M1069.18 419.309C1070.43 421.97 1072.08 424.818 1072.99 427.561M939.047 365.351C946.341 373.455 954.053 380.985 961.617 388.909C964.672 392.108 966.618 397.627 968.389 401.675C969.917 405.17 971.792 408.349 973.608 411.691C975.618 415.39 976.518 419.459 978.122 423.329C980.556 429.199 981.565 436.077 983.165 442.232C985.103 449.683 987.551 456.812 990.148 464.027C991.503 467.789 991.182 471.943 992.511 475.665C992.977 476.969 994.274 477.338 994.274 478.98M1050.77 429.466C1051.74 433.328 1055.6 436.079 1056.49 439.623"
              stroke="#D64F67"
              strokeWidth="4"
              strokeLinecap="round"
            /> */}
        </g>
      </g>
    </svg>
  );
});

export default ManikinSVG;
