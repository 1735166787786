import api from "../config/axios";

const login = (type, bodyData) => {
  return api.post(`/login`, bodyData);
};
const profile = () => {
  return api.get("/me");
};
const getAllUsers = () => {
  return api.get(`/get-all-users`);
};
const getAllUserTypes = () => {
  return api.get(`/user-types`);
};
const createUserType = (data) => {
  return api.post(`/user-type`, data);
};
const deleteUserType = (typeId) => {
  return api.delete(`/user-types/${typeId}`);
};
const getUsersByType = () => {
  return api.get(`/get-students`);
};
const newUserCreate = (dataBody) => {
  return api.post(`/user`, dataBody);
};
const getUserDetails = (userId) => {
  return api.get(`/get-user-details/${userId}`);
};
const userUpdate = (userId, dataBody) => {
  return api.put(`/user/${userId}`, dataBody);
};
const userStatusChange = (userId) => {
  return api.patch(`/change-user-status/${userId}`);
};

const getUsersByTypeAll = (userType) => {
  return api.get(`get-users-by-type/${userType}`);
};
const getUserToken = (userId) => {
  return api.get(`/get-user-token/${userId}`);
};

export {
  login,
  profile,
  getAllUsers,
  getAllUserTypes,
  newUserCreate,
  userUpdate,
  userStatusChange,
  createUserType,
  deleteUserType,
  getUsersByType,
  getUserDetails,
  getUsersByTypeAll,
  getUserToken,
};
