import React, { useEffect, useState } from "react";
import { getErrorCodes } from "../../api/manikin.rest";
import { getErrorCodesCreate } from "../../api/manikin.rest";
import { getErrorCodesEdit } from "../../api/manikin.rest";
import logo from "../../assets/images/login/logo_new.png";
import logo2 from "../../assets/images/login/logo2_new.png";
import AddIcon from "../../components/icons/AddIcon";
import AssignTestModal from "../../components/common/AssignTestModal";
import TooltipUi from "../../components/common/TooltipUi";
import AssignmentAdd from "../../components/icons/AssignmentAdd";
import RightArrow from "../../components/icons/RightArrow";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";

const ErrorCodes = () => {
  const [errorData, setErrorData] = useState();
  const [modalHide, setModalHide] = useState(false);
  const [modalHide1, setModalHide1] = useState(false);
  const userType = localStorage.getItem("userType");
  const [errCodeMsg, setErrCodeMsg] = useState("");
  const [errNameMsg, setErrNameMsg] = useState("");
  const [errorDataId, setErrorDataId] = useState("");

  const [createErrData, setCreateErrData] = useState({
    code: "",
    description: "",
  });

  const [updateErrorCode, setUpdateErrorCode] = useState({
    code: "",
    description: "",
  });

  const data = {};

  const getErrorCodeData = async () => {
    try {
      const res = await getErrorCodes(data);
      console.log("dfsdfsdfsd", res?.data);
      setErrorData(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getErrorCodeData();
  }, []);

  const onClose = () => {
    setModalHide(false);
  };

  const onClose1 = () => {
    setModalHide1(false);
  };

  console.log("fdfsdfsdf", userType);

  const handleCreateErrCodeClick = () => {
    setModalHide(true);
  };

  const handleChange = (e) => {
    setCreateErrData({ ...createErrData, [e.target.name]: e.target.value });
  };

  const userValid = () => {
    let checked = true;

    if (createErrData?.code === "") {
      checked = false;
      setErrCodeMsg("enter userName");
    } else {
      setErrCodeMsg("");
    }
    if (createErrData?.description === "") {
      checked = false;
      setErrNameMsg("enter userName");
    } else {
      setErrNameMsg("");
    }
    return checked;
  };

  const EditErrValid = () => {
    let checked = true;

    if (updateErrorCode?.code === "") {
      checked = false;
      setErrCodeMsg("enter error code");
    } else {
      setErrCodeMsg("");
    }

    if (updateErrorCode?.description === "") {
      checked = false;
      setErrNameMsg("enter description");
    } else {
      setErrNameMsg("");
    }
    return checked;
  };

  const createErrorCode = async () => {
    const data = {
      code: createErrData?.code,
      description: createErrData?.description,
    };
    console.log(data);
    try {
      const res = await getErrorCodesCreate(data);
      console.log("sss", res?.status);
      console.log("data of error code", res);
      if (res?.status === 200) {
        toast.success("new Error Code created");
        getErrorCodeData();
        setModalHide(false);
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        toast.error(err?.response?.data?.data);
      }
    }
  };

  const handleSubmit = () => {
    if (userValid()) {
      createErrorCode();
      setCreateErrData((prevState) => ({
        ...prevState,
        code: "",
        description: "",
      }));
    }
    setModalHide(true);
  };

  const handleEditErrorCode = (data, id) => {
    setErrorDataId(id);
    setUpdateErrorCode(data);
    setModalHide1(true);
  };

  const handleEditChange = (e) => {
    setUpdateErrorCode({ ...updateErrorCode, [e.target.name]: e.target.value });
  };

  const updateErrorCodeData = async () => {
    const data = { ...updateErrorCode };
    try {
      const res = await getErrorCodesEdit(errorDataId, data);
      if (res.status === 200) {
        toast.success(
          `Error Code ${updateErrorCode?.code} Successfully Updated`
        );
        getErrorCodeData();
        setModalHide1(false);
      }
    } catch (error) {
      console.log("dd", error);
    }
  };

  const submitEditErrorCode = () => {
    if (EditErrValid()) {
      updateErrorCodeData();
    }
  };

  return (
    <div className="w-[100vw-60px] h-[100vh] bg-background overflow-y-scroll">
      <div className="z-40 w-max h-max flex  items-center p-2 gap-1 absolute top-0 left-0">
        <a href="http://medtrain.ai" target="_medtrainai">
          <div className="manikin-cont w-[100px] h-[20px]">
            {localStorage.theme === "dark" ? (
              <img src={logo2} alt="logo" className="" />
            ) : (
              <img src={logo} alt="logo" className="" />
            )}
          </div>
        </a>
      </div>

      <div className="px-4 pt-4">
        <div className="flex justify-end gap-3 items-center">
          <div className=" text-headingTxt2 text-2xl font-extrabold ">
            Create Error Code
          </div>
          <div
            className="border-[0.1px] text-textColor flex justify-center items-center"
            onClick={() => {
              handleCreateErrCodeClick();
            }}
          >
            <IoMdAdd />
          </div>
        </div>

        <AssignTestModal
          heading={"Create User"}
          handleClose={onClose1}
          open={modalHide1}
        >
          <div className="p-4 font-extrabold">
            Edit Error Code {updateErrorCode?.code}
          </div>
          <form className="flex w-[500px] h-max flex-col gap-4 px-4 py-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="userName"
                    className="font-medium text-[0.8rem]"
                  >
                    Error Code
                  </label>
                  <input
                    type="text"
                    id=""
                    placeholder="Enter Error Code"
                    className="border-b-[0.1px] focus:outline-none font-medium px-2"
                    name="code"
                    value={updateErrorCode?.code}
                    onChange={(e) => handleEditChange(e)}
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {updateErrorCode?.code === "" ? errCodeMsg : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label htmlFor="unm" className="font-medium text-[0.8rem]">
                    Error Name
                  </label>
                  <input
                    type="text"
                    id=""
                    value={updateErrorCode?.description}
                    placeholder="Enter Error Name"
                    className="border-b-[0.1px] focus:outline-none font-medium px-2"
                    name="description"
                    onChange={(e) => handleEditChange(e)}
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {updateErrorCode?.description === "" ? errNameMsg : ""}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-4">
              <div
                className="hover:cursor-pointer  font-medium"
                onClick={() => setModalHide(false)}
              >
                Cancel
              </div>
              <div
                className="w-max py-[0.05rem] px-[0.5rem] bg-[var(--primary-color)] text-[#fff] hover:cursor-pointer font-medium"
                onClick={() => submitEditErrorCode()}
              >
                Submit
              </div>
            </div>
          </form>
        </AssignTestModal>

        <div className=" h-[100%] grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 py-4  place-items-center overflow-y-scroll">
          {errorData?.map((d, i) => {
            return (
              <div
                key={i}
                className="flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[16rem] h-[16rem] p-2 bg-[var(--secondary-color)]"
              >
                <div className="w-[100%] h-max flex flex-col gap-2">
                  <div>
                    <div className="font-semibold text-xs">Error Code:</div>
                    <div className="font-extrabold ">{d?.code}</div>
                  </div>
                  <div>
                    <div className="font-semibold text-xs">Error Name:</div>
                    <div className="font-extrabold ">{d?.description}</div>
                  </div>
                  <div></div>
                </div>
                {userType === "root" && (
                  <div className="w-[100%] h-max flex justify-end items-center gap-4">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        handleEditErrorCode(d, d?.id);
                      }}
                    >
                      <TooltipUi
                        name="Edit"
                        icons={<MdEdit color="black" />}
                        width="max"
                        placement="bottom"
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <AssignTestModal
          heading={"Edit Manikin"}
          handleClose={onClose}
          open={modalHide}
        >
          <div className="p-4 font-extrabold text-xl text-headingTxt2">
            Create Error Code
          </div>
          <form className="flex w-[500px] h-max flex-col gap-4 px-4 py-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="deviceName"
                    className="text-textColor font-medium text-lg"
                  >
                    Error Code
                  </label>
                  <input
                    type="text"
                    id="deviceName"
                    placeholder="Enter Error Code"
                    className="outline-none placeholder-textColor transition duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={(e) => handleChange(e)}
                    value={createErrData?.code}
                    name="code"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {createErrData?.code === "" ? errCodeMsg : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="macId"
                    className="text-textColor font-medium text-lg"
                  >
                    Error Name
                  </label>
                  <input
                    type="text"
                    id="macId"
                    placeholder="Enter Error Name"
                    className="outline-none placeholder-textColor transition duration-300 border-b-2 focus:border-inputBorderColor font-medium text-textColor bg-modalInputBg px-2 py-2"
                    onChange={(e) => handleChange(e)}
                    value={createErrData?.description}
                    name="description"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {createErrData?.description === "" ? errNameMsg : ""}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-4">
              <div
                className="w-max py-[0.7rem] px-[0.7rem] text-textColor transition-all duration-200 hover:cursor-pointer font-medium"
                onClick={() => setModalHide(false)}
              >
                Cancel
              </div>
              <div
                className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
                onClick={handleSubmit}
              >
                Create
              </div>
            </div>
          </form>
        </AssignTestModal>
      </div>
    </div>
  );
};

export default ErrorCodes;
