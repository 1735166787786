import React, { useEffect, useState } from "react";
import logo from "../../assets/images/login/logo_new.png";
import logo2 from "../../assets/images/login/logo2_new.png";
import spinnerlogo from "../../assets/onlinehealthreport.gif";
import { useParams } from "react-router-dom";
import { getAllManikinsList, getOrgManikins } from "../../api/manikin.rest";
import AddIcon from "../../components/icons/AddIcon";
import AssignTestModal from "../../components/common/AssignTestModal";
import { toast } from "react-toastify";
import { createManikin } from "../../api/device.rest";
import { Link, useNavigate } from "react-router-dom";
import TooltipUi from "../common/TooltipUi";

import RightArrow from "../icons/RightArrow";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";
import { getOrganizations } from "../../api/org.rest";
import { mapManikinToOrg } from "../../api/manikin.rest";
import SendIcon from "../icons/SendIcon";
import { IoSettings } from "react-icons/io5";
import {
  getSensorThresholdByDeviceId,
  updateSensorThreshold,
} from "../../api/mqtt.rest";
import Close from "../icons/Close";
import { removeManikinFromOrg } from "../../api/manikin.rest";
import { MdEdit } from "react-icons/md";
import { updateManikin } from "../../api/device.rest";
import { IoMdAdd } from "react-icons/io";
import { BsArrow90DegRight } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";

const ManikinList = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const userType = localStorage.getItem("userType");
  const [modalHide, setModalHide] = useState(false);
  const [modalHide1, setModalHide1] = useState(false);
  const [modalHide2, setModalHide2] = useState(false);
  const [modalHide3, setModalHide3] = useState(false);
  const [manikinData, setManikinData] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [macId, setMacId] = useState("");
  const [procureFrom, setProcureFrom] = useState("");
  const [deviceNameErr, setDeviceNameErr] = useState("");
  const [macIdErr, setMacIdErr] = useState("");
  const [procureFromErr, setProcureFromErr] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [orgData, setOrgData] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [OrgIdErr, setOrgIdErr] = useState("");
  const [senThresholdData, setSenThresholdData] = useState([]);
  const [dvName, setDvName] = useState("");
  const [value1, setValue1] = useState({});
  const [value2, setValue2] = useState({});
  const [value3, setValue3] = useState({});
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [sensorName, setSensorName] = useState("");
  const [updateManikin1, setUpdateManikin1] = useState({
    subtype_id: "",
    device_name: "",
    mac_id: "",
    status: "Active",
    procure_from: "",
    procure_on: "",
    org_id: orgId,
  });

  const data1 = {
    RightLungBMP: 1,
    LeftLungBMP: 1,
    OesophagusReed: 2,
    TracheaReedEnd: 2,
    TracheaReedStart: 2,
    TracheaReedInter1: 2,
    TracheaReedInter2: 2,
    TeethFSR: 1,
    TracheaFSR: 3,
    AngleRotation: 1,
  };

  const params = useParams();

  const onClose = () => {
    setModalHide(false);
  };

  const onClose1 = () => {
    setModalHide1(false);
  };

  const onClose2 = () => {
    setModalHide2(false);
  };

  const onClose3 = () => {
    setModalHide3(false);
  };

  const getOrgManikin = async (orgId) => {
    await getOrgManikins(orgId)
      .then((resp) => {
        setManikinData(resp?.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getAllManikins = async () => {
    await getAllManikinsList()
      .then((resp) => {
        setManikinData(resp?.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (userType === "root") {
      if (params?.id) {
        getOrgManikin(params?.id);
      } else {
        getAllManikins();
      }
    } else if (userType === "admin" || userType === "instructor") {
      const orgId = localStorage.getItem("orgId");
      getOrgManikin(orgId);
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleAddStudentClick = () => {
    setModalHide(true);
  };

  const userValid = () => {
    let checked = true;

    if (deviceName === "") {
      checked = false;
      setDeviceNameErr("enter device name");
    } else {
      setDeviceNameErr("");
    }
    if (macId === "") {
      checked = false;
      setMacIdErr("enter macId");
    } else {
      setMacIdErr("");
    }
    if (procureFrom === "") {
      checked = false;
      setProcureFromErr("enter procureName");
    } else {
      setProcureFromErr("");
    }
    return checked;
  };

  const updateManikinValid = () => {
    let checked = true;

    if (updateManikin1?.device_name === "") {
      checked = false;
      setDeviceNameErr("enter device name");
    } else {
      setDeviceNameErr("");
    }
    if (updateManikin1?.mac_id === "") {
      checked = false;
      setMacIdErr("enter macId");
    } else {
      setMacIdErr("");
    }
    if (updateManikin1?.procure_from === "") {
      checked = false;
      setProcureFromErr("enter procureName");
    } else {
      setProcureFromErr("");
    }
    return checked;
  };

  const orgValid = () => {
    let checked = true;
    if (orgId === "") {
      checked = false;
      setOrgIdErr("select Organization");
    } else {
      setOrgIdErr("");
    }
    return checked;
  };

  const createManikins = async () => {
    const data = {
      subtype_id: "",
      device_name: deviceName,
      mac_id: macId,
      status: "Active",
      procure_from: procureFrom,
      procure_on: currentDate,
      org_id: "",
    };

    try {
      const res = await createManikin(data);
      if (res?.status === 200) {
        getAllManikins();
        toast.success("manikin created");
        setModalHide(false);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.data}`, 2000);
    }
  };

  const onUserSubmit = () => {
    if (userValid()) {
      createManikins();
      setDeviceName("");
      setMacId("");
      setProcureFrom("");
    }
    setModalHide(true);
  };

  useEffect(() => {
    if (modalHide === false) {
      setDeviceName("");
      setMacId("");
      setProcureFrom("");
    }
  }, [modalHide]);

  const handleAssignClick = (id) => {
    setModalHide1(true);
    setDeviceId(id);
  };

  const handleShowDetails1 = async (id, dName) => {
    setModalHide2(true);
    try {
      const res = await getSensorThresholdByDeviceId(id);
      if (res?.status === 200) {
        setSenThresholdData(res?.data?.data);
        setDvName(dName);
        setDeviceId(id);
      }
    } catch (error) {
      console.log("dhfgdfgj", error);
    }
  };

  const handleShowDetails = (stData, dName, id) => {
    setSenThresholdData(stData);
    setDvName(dName);
    setModalHide2(true);
    setDeviceId(id);
  };

  const getOrganizationsData = async () => {
    await getOrganizations()
      .then((resp) => {
        setOrgData(resp?.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (userType === "root") {
      getOrganizationsData();
    }
  }, []);

  const AssignManikin = async () => {
    try {
      const res = await mapManikinToOrg(orgId, deviceId);
      if (res?.status === 200) {
        getAllManikins();
        toast.success("manikin assigned successfully");
        setModalHide1(false);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const OnAssign = () => {
    if (orgValid()) {
      AssignManikin();
    }
  };

  const DisAssignManikin = async (id, orgId) => {
    try {
      const res = await removeManikinFromOrg(orgId, id);
      if (res?.status === 200) {
        toast.success("manikin disAssigned successfully");
        getAllManikins();
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    if (modalHide1 === false) {
      setOrgId("");
      setOrgIdErr("");
    }

    if (modalHide2 === false) {
      setEditingEnabled(false);
    }
  }, [modalHide1, modalHide2]);

  const handleValueChange = (e, index, valueNumber, senName) => {
    const newValue = e.target.value;
    setSenThresholdData((prevData) => {
      return prevData.map((data, i) => {
        if (i === index) {
          return {
            ...data,
            [`value_${valueNumber}`]: newValue,
            [`sensor_name${senName}`]: sensorName,
          };
        }
        return data;
      });
    });
  };

  const handleEditClick = () => {
    setEditingEnabled(true);
  };

  const handleUpdateClick = async () => {
    setEditingEnabled(false);
    const data = senThresholdData;
    const res = await updateSensorThreshold(data);
    if (res.status === 200) {
      toast.success("SensorThresholdData updated Successfully");
      const orgId = localStorage.getItem("orgId");
      if (userType === "admin") {
        getOrgManikin(orgId);
      }

      setModalHide2(false);
    } else {
      toast.error("error something went wrong");
    }
  };

  Object.keys(data1).forEach((sensorName) => {
    const exists = senThresholdData?.some(
      (data) => data.sensor_name === sensorName
    );
    if (!exists) {
      setSenThresholdData((prevData) => [
        ...prevData,
        { sensor_name: sensorName, value_1: "125.76", device_id: deviceId },
      ]);
    }
  });

  const DisAssignClick = () => {
    window.alert("do you want disAssign the manikin");
  };

  const handleManikinEditClick = (data) => {
    setUpdateManikin1(data);
    setModalHide3(true);
    setDeviceId(data?.device_id);
    setOrgId(data?.orgm_a[0]?.org_id);
  };

  const updateInputChange = (e) => {
    setUpdateManikin1({ ...updateManikin1, [e.target.name]: e.target.value });
  };

  const updateManikinApi = async () => {
    const data = { ...updateManikin1 };
    try {
      const res = await updateManikin(deviceId, data);
      if (res.status === 200) {
        toast.success("manikin successfully updated");
        if (userType === "root") {
          getAllManikins();
        } else if (userType === "admin") {
          const orgId = localStorage.getItem("orgId");
          getOrgManikin(orgId);
        }
        setModalHide3(false);
      }
    } catch (error) {
      console.log("dd", error);
    }
  };

  const onUpdateManikin = () => {
    if (updateManikinValid()) {
      updateManikinApi();
    }
  };

  return (
    <div className="w-[100vw-60px] h-[100vh] bg-background overflow-y-scroll">
      <div className="z-40 w-max h-max flex  items-center p-2 gap-1 absolute top-0 left-0">
        <a href="http://medtrain.ai" target="_medtrainai">
          <div className="manikin-cont w-[100px] h-[20px]">
            {localStorage.theme === "dark" ? (
              <img src={logo2} alt="logo" className="" />
            ) : (
              <img src={logo} alt="logo" className="" />
            )}
          </div>
        </a>
      </div>

      <div className="px-4 pt-4">
        {userType === "root" && manikinData.length === 0 ? (
          <>
            {" "}
            <div className="flex justify-end gap-3 items-center">
              <div className="font-extrabold text-headingTxt2 text-2xl">
                {userType === "root" && "Add"} Manikin
              </div>
              {userType === "root" && (
                <div
                  className="border-[0.1px] text-textColor flex justify-center items-center"
                  onClick={handleAddStudentClick}
                >
                  <IoMdAdd />
                </div>
              )}
            </div>
            <div className=" text-textColor text-xl">Manikins Not Assigned</div>
          </>
        ) : (
          <>
            <div className="flex justify-end gap-3 items-center">
              <div className="font-extrabold text-headingTxt2 text-2xl">
                {userType === "root" && "Add"} Manikin
              </div>
              {userType === "root" && (
                <div
                  className="border-[0.1px] text-textColor flex justify-center items-center"
                  onClick={handleAddStudentClick}
                >
                  {/* <AddIcon /> */}
                  <IoMdAdd />
                </div>
              )}
            </div>
            <div className=" h-[100%]  grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-20 py-4  place-items-center overflow-y-scroll">
              {manikinData?.map((manikinDet, i) => {
                return (
                  <div className="flex bg-background flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[17rem] px-4  ">
                    <div className="w-[100%] h-max flex flex-col gap-2 ">
                      <div className="pb-2">
                        <div className="font-semibold text-textColor text-base">
                          {manikinDet.name}
                        </div>
                      </div>
                      <div>
                        <div className="font-extrabold text-headingTxt2 text-sm">
                          Device Name:
                        </div>
                        <div className="text-textColor font-normal">
                          {manikinDet?.device_name}
                        </div>
                      </div>
                      <div>
                        <div className="text-textColor font-extrabold text-sm">
                          Device Id:
                        </div>
                        <div className="text-textColor font-normal">
                          {manikinDet?.device_id}
                        </div>
                      </div>
                      {userType === "root" && (
                        <div>
                          <div className="text-textColor font-extrabold text-sm">
                            Organization
                          </div>
                          <div className="text-textColor font-normal">
                            {manikinDet?.orgm_a.length === 0
                              ? "Not Assigned"
                              : manikinDet?.orgm_a[0]?.organization?.org_nm}
                          </div>
                        </div>
                      )}

                      <div>
                        <div className="font-semibold text-textColor text-sm">
                          Status:
                        </div>
                        <div className="font-extrabold ">
                          {manikinDet?.status === "Active" &&
                          manikinDet?.orgm_a.length > 0 ? (
                            <span className="text-green-600">Active</span>
                          ) : (
                            <span className="text-red-600">InActive</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-[100%] flex justify-end">
                      {manikinDet?.orgm_a.length === 0 &&
                        userType === "root" && (
                          <div className="w-[100%] h-max flex justify-end items-center gap-4">
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                handleAssignClick(manikinDet?.device_id)
                              }
                            >
                              <TooltipUi
                                name="Assign Manikin"
                                icons={
                                  <BsArrow90DegRight className=" text-textColor" />
                                }
                                width="max"
                                placement="bottom"
                              />
                            </div>
                          </div>
                        )}

                      {manikinDet?.orgm_a.length > 0 && userType === "root" && (
                        <>
                          <div
                            className="cursor-pointer W-[100%] flex justify-end"
                            onClick={() =>
                              DisAssignManikin(
                                manikinDet?.device_id,
                                manikinDet?.orgm_a[0]?.org_id
                              )
                            }
                          >
                            <TooltipUi
                              name="DisAssign Manikin"
                              icons={<MdClose className=" text-textColor" />}
                              width="max"
                              placement="bottom"
                            />
                          </div>
                        </>
                      )}

                      {userType === "root" && (
                        <>
                          <div
                            onClick={() => handleManikinEditClick(manikinDet)}
                          >
                            <TooltipUi
                              name="Edit Manikin"
                              icons={
                                <MdOutlineEdit className="text-textColor" />
                              }
                              width="max"
                              placement="bottom"
                            />
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleShowDetails1(
                                manikinDet?.device_id,
                                manikinDet?.device_name
                              )
                            }
                          >
                            <TooltipUi
                              name="SensorThreshold details"
                              icons={<FiSettings className="text-textColor" />}
                              width="max"
                              placement="bottom"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {userType === "admin" && (
                      <div className="w-[100%] h-max flex justify-end items-center gap-4">
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            handleShowDetails(
                              manikinDet?.sensorThreshold,
                              manikinDet?.device_name,
                              manikinDet?.device_id
                            )
                          }
                        >
                          <TooltipUi
                            name="SensorThreshold details"
                            icons={
                              <IoSettingsOutline className=" text-textColor" />
                            }
                            width="max"
                            placement="bottom"
                          />
                        </div>
                        <div onClick={() => handleManikinEditClick(manikinDet)}>
                          <TooltipUi
                            name="Edit Manikin"
                            icons={<MdEdit className="text-textColor" />}
                            width="max"
                            placement="bottom"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}

        <AssignTestModal
          heading={"Create User"}
          handleClose={onClose}
          open={modalHide}
        >
          <div className="p-4 font-extrabold text-headingTxt2 text-xl">
            Create Manikin
          </div>
          <form className="flex w-[500px] h-max flex-col rounded-md gap-4 px-4 py-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="deviceName"
                    className="font-medium text-lg text-textColor"
                  >
                    Device Name
                  </label>
                  <input
                    type="text"
                    id="deviceName"
                    placeholder="Enter Device Name"
                    className=" outline-none transition duration-300 border-b-2 focus:border-inputBorderColor placeholder-textColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={(e) => setDeviceName(e.target.value)}
                    value={deviceName}
                    name="deviceName"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {deviceName === "" ? deviceNameErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {deviceName === "" ? deviceNameErr : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="macId"
                    className="text-textColor font-medium text-lg"
                  >
                    Mac Id
                  </label>
                  <input
                    type="text"
                    id="macId"
                    placeholder="Enter MacId"
                    className="outline-none transition duration-300 border-b-2 placeholder-textColor focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={(e) => setMacId(e.target.value)}
                    value={macId}
                    name="macId"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {macId === "" ? macIdErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {macId === "" ? macIdErr : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="procureFrom"
                    className="text-textColor font-medium text-lg"
                  >
                    Procure From
                  </label>
                  <input
                    type="text"
                    id="procureFrom"
                    placeholder="Enter Procure From"
                    className="outline-none transition duration-300 border-b-2 placeholder-textColor focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={(e) => setProcureFrom(e.target.value)}
                    value={procureFrom}
                    name="procureFrom"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {procureFrom === "" ? procureFromErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {procureFrom === "" ? procureFromErr : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="procureOn"
                    className="text-textColor font-medium text-lg"
                  >
                    Procure On
                  </label>
                  <input
                    type="text"
                    id="procureOn"
                    className="outline-none transition placeholder-textColor duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg disabled:cursor-not-allowed px-2 py-2	"
                    value={currentDate}
                    name="procureOn"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-4">
              <div
                className="w-max py-[0.7rem] px-[0.7rem] text-textColor  transition-all duration-200 hover:cursor-pointer font-medium"
                onClick={() => setModalHide(false)}
              >
                Cancel
              </div>
              <div
                className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
                onClick={onUserSubmit}
              >
                Create
              </div>
            </div>
          </form>
        </AssignTestModal>

        <AssignTestModal
          heading={"Create User"}
          handleClose={onClose1}
          open={modalHide1}
        >
          <div className="p-4 font-extrabold text-headingTxt2 text-xl">
            Assign Manikin
          </div>
          <form className="flex w-[500px] h-max flex-col gap-4 px-4 py-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="deviceName"
                    className="text-textColor font-medium text-lg"
                  >
                    Device Id
                  </label>
                  <input
                    type="text"
                    id="deviceName"
                    disabled
                    placeholder="Enter Device Name"
                    className=" text-textColor outline-none transition duration-300 border-b-2 focus:border-inputBorderColor bg-modalInputBg font-medium px-2 py-2 disabled:cursor-not-allowed"
                    value={deviceId}
                    name="deviceName"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {deviceName === "" ? deviceNameErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {deviceName === "" ? deviceNameErr : ""}
                </div>
              </div>

              <div className="w-[100%]">
                <FormControl
                  sx={{ width: 452, color: "var(--text-primary-color)" }}
                >
                  <InputLabel
                    sx={{ color: "var(--text-primary-color)" }}
                    id="demo-simple-select-label"
                  >
                    Select Organization
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className=" text-textColor bg-modalInputBg"
                    value={orgId}
                    label="Select Organization"
                    onChange={(e) => setOrgId(e.target.value)}
                  >
                    {orgData?.map((data, i) => {
                      return (
                        <MenuItem
                          value={data?.org_id}
                          key={i}
                          className=" text-textColor "
                        >
                          {data?.org_nm}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div className="text-[0.8rem] text-red-500">
                  {orgId === "" ? OrgIdErr : ""}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-4">
              <div
                className="w-max py-[0.7rem] px-[0.7rem] transition-all duration-200 text-textColor hover:cursor-pointer font-medium"
                onClick={() => setModalHide1(false)}
              >
                Cancel
              </div>
              <div
                className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
                onClick={OnAssign}
              >
                Assign
              </div>
            </div>
          </form>
        </AssignTestModal>

        <AssignTestModal
          heading={"Create User"}
          handleClose={onClose2}
          open={modalHide2}
        >
          <div className="p-4 font-extrabold text-headingTxt2 text-2xl">
            Sensor Threshold
          </div>
          <div className="flex w-[500px] h-[100%] flex-col gap-4 px-4 py-4 overflow-y-scroll">
            <div className="text-textColor font-bold">{dvName}</div>
            <div className="w-[100%] grid grid-cols-2 gap-y-4">
              {senThresholdData?.map((data, i) => (
                <div key={i}>
                  <div>
                    <div className="font-semibold text-headingTxt2 text-xl pb-1">
                      {data?.sensor_name}
                    </div>
                    <div className="flex flex-col gap-2">
                      {data1[data.sensor_name] === 1 && (
                        <div>
                          {data?.value_1 === null || data?.value_1 === "" ? (
                            ""
                          ) : (
                            <label
                              htmlFor=""
                              className="text-textColor text-lg"
                            >
                              Thresholdvalue1
                            </label>
                          )}

                          <input
                            type="text"
                            value={data?.value_1}
                            onChange={(e) =>
                              handleValueChange(e, i, 1, data.sensor_name)
                            }
                            disabled={!editingEnabled}
                            className={`${
                              editingEnabled
                                ? "border-1 border-black p-2"
                                : "p-2 text-textColor bg-modalInputBg"
                            }`}
                          />
                        </div>
                      )}
                      {data1[data.sensor_name] === 2 && (
                        <div>
                          <label htmlFor="" className=" text-textColor text-lg">
                            ThresholdValue1
                          </label>

                          <input
                            type="text"
                            value={data?.value_1}
                            onChange={(e) =>
                              handleValueChange(e, i, 1, data.sensor_name)
                            }
                            disabled={!editingEnabled}
                            className={`${
                              editingEnabled
                                ? "border-1 border-black p-2"
                                : "p-2 text-textColor bg-modalInputBg"
                            }`}
                          />

                          <label htmlFor="" className=" text-textColor text-lg">
                            ThresholdValue2
                          </label>

                          <input
                            type="text"
                            value={data?.value_2}
                            onChange={(e) =>
                              handleValueChange(e, i, 2, data.sensor_name)
                            }
                            disabled={!editingEnabled}
                            className={`${
                              editingEnabled
                                ? "border-1 border-black p-2"
                                : "p-2 text-textColor bg-modalInputBg"
                            }`}
                          />
                        </div>
                      )}
                      {data1[data.sensor_name] === 3 && (
                        <div>
                          <label htmlFor="" className=" text-textColor text-lg">
                            ThresholdValue1
                          </label>

                          <input
                            type="text"
                            value={data?.value_1}
                            onChange={(e) =>
                              handleValueChange(e, i, 1, data.sensor_name)
                            }
                            disabled={!editingEnabled}
                            className={`${
                              editingEnabled
                                ? "border-1 border-black p-2"
                                : "p-2 text-textColor bg-modalInputBg"
                            }`}
                          />

                          <label htmlFor="" className="text-textColor text-lg">
                            ThresholdValue2
                          </label>

                          <input
                            type="text"
                            value={data?.value_2}
                            onChange={(e) =>
                              handleValueChange(e, i, 2, data.sensor_name)
                            }
                            disabled={!editingEnabled}
                            className={`${
                              editingEnabled
                                ? "border-1 border-black p-2"
                                : "p-2 text-textColor bg-modalInputBg"
                            }`}
                          />

                          <label htmlFor="" className="text-textColor text-lg">
                            ThresholdValue3
                          </label>

                          <input
                            type="text"
                            value={data?.value_3}
                            onChange={(e) =>
                              handleValueChange(e, i, 3, data.sensor_name)
                            }
                            disabled={!editingEnabled}
                            className={`${
                              editingEnabled
                                ? "border-1 border-black p-2"
                                : "p-2 text-textColor bg-modalInputBg"
                            }`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {/* <div>
                <div className='font-semibold pb-1'>
                AngleRotation
                </div>
                <div className='flex flex-col gap-2'>
                  <div className=''>
                    <label htmlFor="">value1</label>
                    <input type="text" value="128.5" disabled />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="flex w-[100%] justify-end gap-4 pb-[5rem]">
              <div
                className="w-max py-[0.7rem] px-[0.7rem] text-textColor transition-all duration-200 hover:cursor-pointer font-medium"
                onClick={handleEditClick}
              >
                Edit
              </div>
              <div
                className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
                onClick={handleUpdateClick}
              >
                Save
              </div>
            </div>
          </div>
        </AssignTestModal>

        <AssignTestModal
          heading={"Edit Manikin"}
          handleClose={onClose3}
          open={modalHide3}
        >
          <div className="p-4 font-extrabold text-xl text-headingTxt2">
            Edit Manikin
          </div>
          <form className="flex w-[500px] h-max flex-col rounded-md gap-4 px-4 py-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="deviceName"
                    className="text-textColor font-medium text-lg"
                  >
                    Device Name
                  </label>
                  <input
                    type="text"
                    id="deviceName"
                    placeholder="Enter Device Name"
                    className=" outline-none transition duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={updateInputChange}
                    value={updateManikin1?.device_name}
                    name="device_name"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {deviceName === "" ? deviceNameErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {deviceName === "" ? deviceNameErr : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="macId"
                    className="text-textColor font-medium text-lg"
                  >
                    Mac Id
                  </label>
                  <input
                    type="text"
                    id="macId"
                    placeholder="Enter MacId"
                    className="outline-none transition duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={updateInputChange}
                    value={updateManikin1?.mac_id}
                    name="mac_id"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {macId === "" ? macIdErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {macId === "" ? macIdErr : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="procureFrom"
                    className="text-textColor font-medium text-lg"
                  >
                    Procure From
                  </label>
                  <input
                    type="text"
                    id="procureFrom"
                    placeholder="Enter Procure From"
                    className="outline-none transition duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg font-medium px-2 py-2"
                    onChange={updateInputChange}
                    value={updateManikin1?.procure_from}
                    name="procure_from"
                  />
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {procureFrom === "" ? procureFromErr : ""}
                </div>
                <div className="text-[0.8rem] text-red-500">
                  {procureFrom === "" ? procureFromErr : ""}
                </div>
              </div>

              <div className="flex flex-col justify-start">
                <div className="flex flex-col justify-start gap-2">
                  <label
                    htmlFor="procureOn"
                    className="text-textColor font-medium text-lg"
                  >
                    Procure On
                  </label>
                  <input
                    type="text"
                    id="procureOn"
                    className="outline-none transition duration-300 border-b-2 focus:border-inputBorderColor text-textColor bg-modalInputBg py-2 px-2 disabled:cursor-not-allowed	"
                    value={currentDate}
                    name="procureOn"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-4">
              <div
                className="w-max py-[0.7rem] px-[0.7rem]  transition-all duration-200 text-textColor hover:cursor-pointer font-medium"
                onClick={() => setModalHide3(false)}
              >
                Cancel
              </div>
              <div
                className="w-max py-[0.7rem] px-7 bg-buttonBg transition-all duration-200 text-btnText hover:cursor-pointer font-medium"
                onClick={onUpdateManikin}
              >
                Edit
              </div>
            </div>
          </form>
        </AssignTestModal>
      </div>
    </div>
  );
};

export default ManikinList;
