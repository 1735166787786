import React, { useEffect, useState } from "react";
import Graph from "./Graph";
import "./graph-style.css";
import GraphData from "./GraphData";
import * as XandY from "./dataset_for_graph_pattern";
import {
  COLOR_CODE_FOR_ECG,
  COLOR_CODE_FOR_ETCO2,
  COLOR_CODE_FOR_SPO2,
  ECG_STROKE_WIDTH,
  ETCO2_STROKE_WIDTH,
  SPO2_STROKE_WIDTH,
} from "../../../components/common/GraphInfo";
function VitalMonitor({
  changeTheRangeForEcg,
  changeTheRangeForSpo2,
  changeTheRangeForEtc02,
  widthForGraph,
  height,
  xStretch = 1,
}) {
  const margin = { top: 30, right: 20, bottom: 30, left: 20 };
  const width = 800 - margin.left - margin.right;
  const heightForGraph = (height / 3) * 0.9; //height of each graph

  const DATA_CARD_WIDTH_IN_PIXELS = 160;

  const initialWidth =
    window.innerWidth * 0.8 * 0.4 - DATA_CARD_WIDTH_IN_PIXELS;
  const scale = (widthForGraph - DATA_CARD_WIDTH_IN_PIXELS) / initialWidth;

  // useEffect(() => {}, [changeTheRange]);
  useEffect(() => {
    console.log(scale, "scale factor.");
    document.querySelectorAll(".graph-container").forEach((element) => {
      element.style.height = `${heightForGraph + 25}px`;
    });
  }, [widthForGraph]);
  return (
    <div className=" flex flex-col justify-start grow">
      <Graph
        incrementalX={XandY.incrementalXForEcg}
        yCoordinates={XandY.yCoordinatesOfEcg}
        color_code={COLOR_CODE_FOR_ECG}
        width={widthForGraph - DATA_CARD_WIDTH_IN_PIXELS}
        height={heightForGraph * 0.9}
        max_points={changeTheRangeForEcg * parseInt(scale)}
        stroke={ECG_STROKE_WIDTH}
        domain={5000 * parseInt(scale * xStretch)}
        smooth={true}
        pathId="line1"
        gap={5}
        upperLowerRange={100}
        scale={scale}
      />
      <Graph
        incrementalX={XandY.incrementalXForSpo2}
        yCoordinates={XandY.yCoordinatesOfSpo2}
        color_code={COLOR_CODE_FOR_SPO2}
        width={widthForGraph - DATA_CARD_WIDTH_IN_PIXELS}
        height={heightForGraph * 0.9}
        max_points={changeTheRangeForSpo2 * parseInt(scale)}
        stroke={SPO2_STROKE_WIDTH}
        domain={5000 * parseInt(scale * xStretch)}
        smooth={true}
        pathId="line2"
        gap={5}
        upperLowerRange={100}
        scale={scale}
      />
      <Graph
        incrementalX={XandY.incrementalXForEtco2}
        yCoordinates={XandY.yCoordinatesOfEtco2}
        color_code={COLOR_CODE_FOR_ETCO2}
        width={widthForGraph - DATA_CARD_WIDTH_IN_PIXELS}
        height={heightForGraph * 0.9}
        max_points={changeTheRangeForEtc02 * parseInt(scale)}
        stroke={ETCO2_STROKE_WIDTH}
        domain={10000 * parseInt(scale * xStretch)}
        smooth={false}
        pathId="line3"
        gap={15}
        upperLowerRange={20}
        scale={scale}
      />
    </div>
  );
}

export default VitalMonitor;
