import React, { useEffect, useState } from "react";
import { getOrganizations } from "../../api/org.rest";
import { getAllUsers, getUserToken } from "../../api/user.rest";
import logo from "../../assets/images/login/logo_new.png";
import logo2 from "../../assets/images/login/logo2_new.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { useDarkMode } from "../../hooks/useDarkMode";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Select from "react-select";
import { toast } from "react-toastify";

const Impersonation = () => {
  const [orgData, setOrgData] = useState([]);
  const [users, setUsers] = useState([]);
  const darkMode = useDarkMode((state) => state.theme);

  // ag grid column definition

  const [columnDefs] = useState([
    {
      headerName: "User ID",
      field: "utype_id",
      sortable: true,
      filter: true,
      checkboxSelection: true,
    },
    { headerName: "Name", field: "name", sortable: true, filter: true },
    { headerName: "Mobile", field: "umob", sortable: true, filter: true },
    {
      headerName: "UserType",
      field: "role.utype_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Reg.Date",
      field: "ureg_date",
      sortable: true,
      filter: true,
    },
    {
      headerName: "UserAddress",
      field: "Uaddress",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => {
        const users = params.data;
        const handleImpersonate = async (event) => {
          event.preventDefault();

          const confirmation = window.confirm(
            "This will log you out and redirect to the login page. Are you sure?"
          );

          if (confirmation) {
            // toast message
            toast.info("You're logged out.");

            // Delay the redirection to allow the toast to be displayed
            setTimeout(async () => {
              // Logging out the user
              localStorage.removeItem("token");
              sessionStorage.clear();
              const response = await getUserToken(users.uid); //geting token
              const token = Object.values(response.data.data); // storing token
              const expirationDate = new Date();
              expirationDate.setMinutes(expirationDate.getMinutes() + 1);
              document.cookie = `token=${token}; expires=${expirationDate.toUTCString()}; path=/; secure; samesite=strict;`;

              // login URL
              const loginUrl = `https://${window.location.hostname}/login-sys?u=${users.Unm}`; //using the token

              // Open login page in a new tab
              window.open(loginUrl, "_blank", "noopener,noreferrer");

              // Optionally, navigate away from the current page
              window.location.href = `https://${window.location.hostname}/login-sys`; // This will redirect the current page to the login page
            }, 2000);
          } else {
            console.log("Impersonation canceled by user.");
          }
        };

        return (
          <button
            onClick={handleImpersonate}
            className="bg-emerald-500 hover:bg-emerald-600  rounded-none px-4  text-center text-textColor"
          >
            Investigate
          </button>
        );
      },
    },
  ]);

  // const getToken = async (uid) => {
  //   await getUserToken()
  //     .then((resp) => {
  //       setToken(resp?.data?.data);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // };

  // useEffect(() => {
  //   getToken();
  //   console.log(token);
  // }, [users]);

  // get all organizations
  const getAllOrg = async () => {
    await getOrganizations()
      .then((resp) => {
        setOrgData(resp?.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // fetch all users
  const fetchUsers = async (org_id) => {
    try {
      const response = await getAllUsers(org_id); // Fetch users API
      const filteredUsers = response?.data?.data.filter(
        (user) => user.org_id === org_id // Ensure filtering by org_id
      );
      setUsers(filteredUsers || []); // Set the filtered users in state
      // console.log({ filteredUsers });
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]); // Handle error by clearing users
    }
  };

  // this useeffect gets all organizations for dropdown menu
  useEffect(() => {
    getAllOrg();
  }, []);

  const selectOptions = orgData.map((org) => ({
    label: org?.org_nm,
    value: org?.org_id,
  }));
  // console.log({ orgData });

  const handleOrgChange = (selectedOption) => {
    if (selectedOption) {
      fetchUsers(selectedOption.value);
    } else {
      setUsers([]);
    }
  };

  // styles for dropdown menu
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "5px",
      boxShadow: "none",
      textAlign: "left",
    }),
    option: (provided) => ({
      ...provided,
      color: "var(--text-primary-color)",
      backgroundColor: "var(--bg-color-modal)",
    }),
  };

  return (
    <div className="relative bg-background h-screen w-full text-textColor">
      {/* logo */}
      <div className="z-40 w-max h-max flex  items-center p-2 gap-1 absolute top-0 left-0">
        <a href="http://medtrain.ai" target="_medtrainai">
          <div className="manikin-cont w-[100px] h-[20px]">
            {localStorage.theme === "dark" ? (
              <img src={logo2} alt="logo" className="" />
            ) : (
              <img src={logo} alt="logo" className="" />
            )}
          </div>
        </a>
      </div>
      {/* react select dropdown menu */}
      <div className="absolute right-10 flex  gap-2 pt-2 items-center justify-center">
        <Select
          options={selectOptions}
          styles={customStyles}
          placeholder="Select an organization"
          isClearable
          onChange={handleOrgChange}
        />
      </div>
      {/* ag grid */}
      <div
        className={`${
          darkMode === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"
        } p-2 absolute left-[50%] translate-x-[-50%] top-28 overflow-x-auto w-[96%]`} //applying the Data Grid theme
        style={{
          height: 500,
        }}
      >
        <AgGridReact
          rowData={users} // Provide users as the row data
          columnDefs={columnDefs} // Define the column structure
          pagination={true} // Enable pagination
          paginationPageSize={15} // Set pagination size
          rowSelection="single"
        />
      </div>
    </div>
  );
};

export default Impersonation;
