let incrementalXForEcg = [
  8, 22, 22, 8, 37, 11, 18, 18, 18, 15, 11, 48, 20, 13, 13, 40, 40, 13, 13, 20,
  128, 8, 22, 22, 8, 37, 11, 18, 18, 18, 15, 11, 48, 20, 13, 13, 40, 40, 13, 13,
  20,
];
let yCoordinatesOfEcg = [
  0, 6, 9, 6, 0, 0, -3, 48, 96, 48, -9, 0, 0, 4, 9, 16, 22, 16, 9, 4, 0, 0, 7,
  10, 7, 0, 0, -4, 50, 100, 50, -10, 0, 0, 5, 10, 17, 23, 17, 10, 5,
];
let incrementalXForSpo2 = [43, 54, 27, 27, 16, 27, 65, 27, 136, 120];

let yCoordinatesOfSpo2 = [10, 0, 0, 59, 91, 99, 92, 59, 49, 39];

let incrementalXForEtco2 = [
 214,214, 2285, 214, 214, 1142
];
let yCoordinatesOfEtco2 = [
  0, 69, 82, 92, 78, 4
];
export {
  incrementalXForEcg,
  yCoordinatesOfEcg,
  incrementalXForSpo2,
  yCoordinatesOfSpo2,
  incrementalXForEtco2,
  yCoordinatesOfEtco2,
};
