const COLOR_CODE_FOR_ECG = "#3BB143";
const COLOR_CODE_FOR_SPO2 = "#00C4FF";
const COLOR_CODE_FOR_ETCO2 = "#ffffff";
const ECG_CATEGORY = "ECG bpm";
const SPO2_CATEGORY = "SpO2 %";
const ETCO2_CATEGORY = "ETCO2 kPa";
const GRAPH_TIME=6000
const ECG_STROKE_WIDTH = 4;
const SPO2_STROKE_WIDTH = 4;
const ETCO2_STROKE_WIDTH = 4;

export {
  COLOR_CODE_FOR_ECG,
  COLOR_CODE_FOR_SPO2,
  COLOR_CODE_FOR_ETCO2,
  ECG_CATEGORY,
  SPO2_CATEGORY,
  ETCO2_CATEGORY,
  GRAPH_TIME,
  ECG_STROKE_WIDTH,
  SPO2_STROKE_WIDTH,
  ETCO2_STROKE_WIDTH
};
